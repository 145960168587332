import { useEffect, useState } from "react";
import NavigationBar from "../navigation/navbar";
import useWindowDimensions from "../tools/dimensions";
import { Button, Flex } from "antd";
import { Divider } from "antd";

import img1 from "../assets/images/Rectangle 30.png";
import img2 from "../assets/images/Rectangle 31.png";
import img3 from "../assets/images/Rectangle 32.png";
import img4 from "../assets/images/Rectangle 33.png";
import Footer from "../navigation/footer";
import { useNavigate } from "react-router-dom";
import semiCircle1 from "../assets/images/semicircle.svg"
import wecode from "../assets/images/wecode.svg"
import CareersData from "../datas/careers";

const Component1 = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "50px",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginTop: 10 }} className="font-weight-normal display-1 px-5">
        Gaya is Hiring
      </h1>

      <h1
        style={{
          fontSize: "18px",
          marginTop: 20,
          color: "#656565",
        }}
      >
        Gaya is looking for talented and passionate people to join our team.
      </h1>
      <div style={{ marginTop: 20 }}>
        <a
          href="#Ouropenings"
          className="btn btn-primary"
          style={{
            backgroundColor: "#521184",
            borderRadius: 100,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 13,
            paddingBottom: 20,
            // width: "247x",
            textAlign: "center",
            borderColor: "transparent",
            height: "52px",
          }}
        >
          See open positions
        </a>
      </div>
    </div>
  );
};

const Component1Mobile = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "50px",
        alignItems: "center",
      }}
    >
      <h1 style={{}} className="display-1 px-5">
        Gaya is Hiring
      </h1>
      <h1 style={{ fontSize: "18px", margin: 20, color: "#656565" }}>
        Gaya is looking for talented and passionate people to join our team.
      </h1>
      <div style={{ marginTop: 20 }}>
        <a
          href="#Ouropenings"
          className="btn btn-primary"
          style={{
            backgroundColor: "#521184",
            borderRadius: 100,
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 13,
            paddingBottom: 20,
            // width: "247x",
            textAlign: "center",
            borderColor: "transparent",
            height: "52px",
          }}
        >
          See open positions
        </a>
      </div>
    </div>
  );
};

const Component2 = () => {
  return (
    <div style={{ width: "968px" }}>
      <h1
        style={{
          fontSize: "49px",
          color: "#2B2928",
          marginTop: 30,
          marginBottom: 60,
        }}
      >
        Why you should join
      </h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <div
          style={{
            width: "368px",
            height: "409px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "324px", height: "74px" }}>
            <p
              style={{
                fontSize: "30px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              You’re an entrepreneur at heart
            </p>
          </div>

          <div
            style={{
              width: "368px",
              height: "319px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              We love experimenting and being scrappy. Join us to be exposed to
              all the phases of a growing startup. You will get exposure to our
              fundraising, team building, culture development, and more.
            </p>
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              If entrepreneurship is something you want to pursue, we’ll help
              you get the right foundation. In fact, we’re happy to support you
              in the application process to Business School if that’s on your
              horizon :)
            </p>
          </div>
        </div>

        <img src={img1} style={{ width: "468px", height: "424px" }} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 100,
          alignItems: "center",
        }}
      >
        <img src={img2} style={{ width: "468px", height: "424px" }} />

        <div
          style={{
            width: "368px",
            height: "293px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "324px", height: "74px" }}>
            <p
              style={{
                fontSize: "30px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              Driven by impact. Not hype
            </p>
          </div>

          <div
            style={{
              width: "368px",
              height: "203px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              You're someone who looks beyond the AI hype, focusing on solving
              deep, real-world pain points with a pragmatic lens. At Gaya, your
              work directly addresses the challenges in the insurance sector.
              Here, AI isn't just a buzzword; it's a tool for meaningful change.
              Join us to apply your skills where they truly make a difference,
              shaping a more efficient and equitable insurance landscape.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 100,
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "368px",
            height: "359px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "368px", height: "111px" }}>
            <p
              style={{
                fontSize: "30px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              You like what we are working on and love a challenge
            </p>
          </div>

          <div
            style={{
              width: "368px",
              height: "232px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              We empower insurance agents to quote and manage their workflows
              10x faster than they currently do it. Our core principle is to
              build a product that is intuitive and simple to use by anyone, yet
              remain extremely powerful. Join our team if you’d like to create
              pragmatic and intuitive products that just work (beyond mere
              demos).
            </p>
          </div>
        </div>

        <img src={img3} style={{ width: "468px", height: "424px" }} />
      </div>
    </div>
  );
};

const Component2Mobile = () => {
  return (
    <div style={{ width: "365px" }}>
      <h1
        style={{
          fontSize: "30px",
          color: "#2B2928",
          marginTop: 30,
          marginBottom: 60,
        }}
      >
        Why you should join
      </h1>

      <div
        style={{
          alignItems: "center",
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={img1} style={{ width: "335px", height: "303px" }} />

        <div
          style={{
            marginTop: 30,
            width: "335px",
            // height: "455px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "324px" }}>
            <p
              style={{
                fontSize: "25px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              You’re an entrepreneur at heart
            </p>
          </div>

          <div
            style={{
              width: "335px",
              // height: "377px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              We love experimenting and being scrappy. Join us to be exposed to
              all the phases of a growing startup. You will get exposure to our
              fundraising, team building, culture development, and more.
            </p>
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              If entrepreneurship is something you want to pursue, we’ll help
              you get the right foundation. In fact, we’re happy to support you
              in the application process to Business School if that’s on your
              horizon :)
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          alignItems: "center",
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={img2} style={{ width: "335px", height: "303px" }} />

        <div
          style={{
            marginTop: 30,
            width: "335px",
            // height: "310px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "335px" }}>
            <p
              style={{
                fontSize: "25px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              Driven by impact. Not hype
            </p>
          </div>

          <div
            style={{
              width: "335px",
              // height: "232px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              You're someone who looks beyond the AI hype, focusing on solving
              deep, real-world pain points with a pragmatic lens. At Gaya, your
              work directly addresses the challenges in the insurance sector.
              Here, AI isn't just a buzzword; it's a tool for meaningful change.
              Join us to apply your skills where they truly make a difference,
              shaping a more efficient and equitable insurance landscape.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          alignItems: "center",
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={img3} style={{ width: "335px" }} />
        <div
          style={{
            marginTop: 30,
            width: "335px",
            // height: "341px",
            textAlign: "left",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ width: "335px" }}>
            <p
              style={{
                fontSize: "25px",
                color: "#2B2928",
                lineHeight: "37.08px",
              }}
            >
              You like what we are working on and love a challenge
            </p>
          </div>

          <div
            style={{
              width: "335px",
              // height: "232px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                color: "#656565",
                lineHeight: "29.16px",
              }}
            >
              We empower insurance agents to step in and help their clients
              reduce their monthly loan payments (starting with car loans). Why
              not go directly to the consumer? Because refinancing car loans,
              unlike mortgage loans, require way more trust that an ad or a
              direct mailer can possibly carry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Component3 = () => {
  const navigate = useNavigate();
  const [careers, setCareers] = useState([])
  let decodedTitle
  useEffect(() => {
    setCareers(CareersData)
  }, [])

  return (
    <div
      id="Ouropenings"
      style={{
        width: "100%",
        // height: "483px",
        backgroundColor: "#FEF7F4",
        marginTop: 150,
        flexDirection: "column",
        display: "flex",

      }}
    >


      <div
        style={{
          paddingTop: 0,
          backgroundImage: `url("${semiCircle1}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'top right',
          backgroundSize: "200px 200px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          display: "flex",
          padding: 60,
          paddingBottom: 140,
          paddingTop: 140,
          flexDirection: "column"
        }}
      >
        <div style={{

        }}>

        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "234px",
              height: "49px",
              marginBottom: 40,
            }}
          >
            <h1 style={{ fontSize: "36px", color: "#1E1F22", fontWeight: 600 }}>
              Our openings
            </h1>
          </div>

          {
            careers.map((item, index) => {
              decodedTitle = item.title.replace("/", "**")
              return (
                <div
                  key={index}
                  style={{
                    width: "968px",
                    height: "134px",
                    borderRadius: "20px",
                    borderWidth: "1px",
                    borderColor: "#E7E7E7",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    marginBottom: 25
                  }}
                >
                  <div style={{ width: "400px", height: "57px", textAlign: "left" }}>
                    <h1
                      style={{
                        //width: "276px",
                        height: "33px",
                        fontSize: "24px",
                        color: "#1E1F22",
                      }}
                    >
                      {item.title}
                    </h1>
                    <a
                      style={{
                        //marginTop : 10,
                        textDecoration: "none",
                        width: "114px",
                        height: "19px",
                        color: "#EF5A24",
                        fontSize: "14px",
                      }}

                      href={"/careers/" + decodedTitle.toLowerCase()}
                    >
                      See more details
                    </a>

                  </div>

                  <div style={{ width: "81px", height: "50px", textAlign: "left" }}>
                    <h1
                      style={{
                        width: "77px",
                        height: "18px",
                        fontSize: "24px",
                        color: "#1E1F22",
                      }}
                    >
                      Location
                    </h1>
                    <p
                      style={{
                        width: "81px",
                        height: "24px",
                        color: "#1E1F22",
                        fontSize: "14px",
                      }}
                    >
                      Remote
                    </p>
                  </div>

                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#521184",
                      borderRadius: 100,
                      width: "178px",
                      height: "52px",
                    }}
                    onClick={() => {
                      //navigate("/careers/apply")
                      const decodedTitle = item.title.replace("/", "**")
                      window.location.href = "/careers/" + decodedTitle.toLowerCase();
                    }}
                  >
                    Apply now
                  </Button>
                </div>
              )

            })
          }




        </div>
      </div>

    </div>
  );
};

const Component3Mobile = () => {
  const [careers, setCareers] = useState([])
  let decodedTitle
  useEffect(() => {
    setCareers(CareersData)
  }, [])

  return (
    <div
      id="Ouropenings"
      style={{
        width: "100%",
        //height: "483px",
        backgroundColor: "#FEF7F4",
        marginTop: 150,

      }}
    >
      <div
        style={{
          paddingTop: 0,
          backgroundImage: `url("${semiCircle1}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: 'top right',
          backgroundSize: "100px 100px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          //height: "483px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 50 }}>
          <div style={{ width: "163px", height: "34px", marginBottom: 10, marginTop: 80 }}>
            <h1 style={{ fontSize: "25px", color: "#1E1F22" }}>Our openings</h1>
          </div>

          {
            careers.map((item, index) => {
              decodedTitle = item.title.replace("/", "**")
              return (
                <div
                  style={{
                    width: "335px",
                    height: "283px",
                    borderRadius: "20px",
                    borderWidth: "1px",
                    borderColor: "#E7E7E7",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginBottom: 25                  
                  }}
                >
                  <div style={{ width: "295px", height: "53px", textAlign: "left" }}>
                    <h1
                      style={{
                        width: "276px",
                        height: "33px",
                        fontSize: "21px",
                        color: "#1E1F22",
                      }}
                    >
                      {item.title}
                    </h1>
                    <a
                      style={{
                        width: "114px",
                        height: "19px",
                        color: "#EF5A24",
                        fontSize: "14px",
                        textDecoration: "none",
                      }}

                      href={"/careers/" + decodedTitle.toLowerCase()}
                    >
                      See more details
                    </a>
                  </div>

                  <div style={{ width: "295px", height: "50px", textAlign: "left" }}>
                    <h1
                      style={{
                        width: "77px",
                        height: "18px",
                        fontSize: "24px",
                        color: "#1E1F22",
                      }}
                    >
                      Location
                    </h1>
                    <p
                      style={{
                        width: "81px",
                        height: "24px",
                        color: "#1E1F22",
                        fontSize: "14px",
                      }}
                    >
                      Remote
                    </p>
                  </div>

                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#521184",
                      borderRadius: 100,
                      width: "295px",
                      height: "52px",
                    }}
                    onClick={() => {
                      //navigate("/careers/apply")
                      const decodedTitle = item.title.replace("/", "**")
                      window.location.href = "/careers/" + decodedTitle.toLowerCase();
                    }}
                  >
                    Apply now
                  </Button>
                </div>
              )
            })
          }

        </div>
      </div>

    </div>
  );
};

export default function Page3() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

  return (
    <div>
      <NavigationBar isMobile={isMobile} selected={"Careers"} />
      <svg
        style={{ position: "absolute", top: "17%", right: 0, zIndex: 3 }}
        width="18%"
        height="78%"
        viewBox="0 0 314 808"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="404.264"
          y="-20"
          width="600"
          height="600"
          rx="50"
          transform="rotate(45 404.264 -20)"
          fill="#FEF7F4"
        />
      </svg>
      <div
        style={{
          // width: screenWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component1Mobile /> : <Component1 />}
      </div>
      <div
        style={{
          width: "90%",
          alignItems: "center",
          margin: "0 auto",
          marginTop: "50px",
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component2Mobile /> : <Component2 />}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component3Mobile /> : <Component3 />}
      </div>{" "}
      {/* */}
      <Footer />
    </div>
  );
}
