const CareersData = [
    {
        title : " UI/UX Designer",
        description : `<h1><strong> UI/UX Designer</strong></h1>
        </br>
        <h2><strong>Role Summary</strong></h2>
        <p>We're looking for a  UI/UX Designer to enhance the usability and aesthetic appeal of our products, with a specific focus on creating an experience that leaves users feeling exhilarated. The ideal candidate will bring a fresh perspective to our design team, focusing on making our product not just easy to use, but also genuinely enjoyable, tapping into the positive psychological impact of a well-designed interface.</p>
        <h2><strong>Responsibilities</strong></h2>
        <ul>
        <li>Design intuitive and visually appealing UI/UX for our browser extension and digital platforms, ensuring the product is accessible and engaging.</li>
        <li>Experiment with design elements that contribute to a sense of excitement and satisfaction for users, aiming to make Gaya a product that people are thrilled to use.</li>
        <li>Develop and maintain a style guide that reflects our brand identity and supports a cohesive user experience across all platforms.</li>
        <li>Participate in user research, feedback sessions, and discovery calls to understand user needs and preferences, using insights to guide design decisions.</li>
        <li>Collaborate with the product team, founders, and broader team in brainstorming and powwow sessions to align on design strategies and user experience goals.</li>
        </ul>
        <h2><strong>Ideal Candidate Profile</strong></h2>
        <ul>
        <li>Experience in UI/UX design, particularly with products that require a high level of user engagement and satisfaction (particularly in projects involving browser extensions).</li>
        <li>Strong design skills, with a portfolio demonstrating a focus on creating intuitive and enjoyable user experiences.</li>
        <li>An understanding of how good design can positively affect user behavior and engagement, without over-relying on explicit instructional aids.</li>
        <li>Ability to work collaboratively in a dynamic startup environment, incorporating user feedback into iterative design processes.</li>
        <li>Willingness to work  (20 hours a week) with the potential for a full-time role, demonstrating a deep commitment to the product's success.</li>
        </ul>
        <p>&nbsp;</p>
        <h2><strong>Location</strong></h2>
        <p>Gaya is a remote-first company, offering the flexibility to work from anywhere.</p>
        <h2><strong>Reporting Structure</strong></h2>
        <p>You will report directly to the CTO, playing a critical role in shaping the future of Gaya's product experience, focusing on creating an interface that not only meets functional needs but also delivers a memorable and enjoyable user journey.</p>
        <h2><strong>Join Us</strong></h2>
        <p>If you're passionate about leveraging design to enhance user satisfaction and are excited about contributing to a product that aims to be intuitive and exhilarating to use, we'd love to welcome you to our team.</p>
        <p>&nbsp;</p>
        <p>If interested, please reach out with your resume to <span style="color: #EF5A24;">jp@gaya.ai</span></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>`
    },
    /* {
        title : "Lead Engineer",
        description : `<div class=WordSection1>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Lead
Engineer<o:p></o:p></span></b></p>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Role
Summary</span></b><span style='font-size:24.0pt;line-height:116%;font-family:
"Calibri Light",sans-serif;mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><span sans-serif;
mso-ansi-language:EN-US'>As a Lead Engineer at Gaya, you will take on a crucial
leadership role in shaping both the front-end and back-end architecture of our
platform. You will be instrumental in driving the development of our browser
extension, client portal, and backend services, ensuring a seamless and secure
user experience. This role offers a unique opportunity to lead a
high-performing team, manage complex integrations, and contribute to the
development of cutting-edge AI technologies within a fast-paced startup
environment.<o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Responsibilities</span></b><span
style='font-size:24.0pt;line-height:116%;font-family:"Calibri Light",sans-serif;
mso-ansi-language:EN-US'><o:p></o:p></span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Technical
     Leadership:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Oversee the end-to-end development and
     enhancement of our browser extension within the Google Chrome Extension
     ecosystem using JavaScript, React/TypeScript, and our Node.js-based
     backend hosted on <span class=SpellE>Supabase</span>.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Full-Stack
     Development:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Contribute to the design and implementation of
     our back-end services, including managing our in-house APIs, third-party
     data integrations, and ensuring the scalability and reliability of our
     architecture.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>AI
     Integration and Privacy:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Lead the implementation and integration of AI
     technologies, both proprietary models and third-party solutions, with a
     strong emphasis on data privacy and ethical AI practices.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Team
     Management:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Mentor and manage a growing team of engineers,
     fostering a culture of collaboration, continuous improvement, and
     technical excellence.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Code
     Quality and Reviews:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Conduct thorough code reviews, ensuring that
     both front-end and back-end code is maintainable, scalable, and secure.
     Advocate for best practices across the entire technology stack.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Cross-Functional
     Collaboration:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Work closely with cross-functional teams to
     ensure that engineering efforts align with business goals and deliver
     high-impact results.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l0 level1 lfo1;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Architectural
     Decision-Making:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Play a key role in the architectural decisions
     that shape the future of our technology, balancing the needs for
     innovation with the demands of a fast-moving startup environment.<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span sans-serif;
mso-ansi-language:EN-US'>Ideal Candidate Profile</span></b><span
sans-serif;mso-ansi-language:EN-US'><o:p></o:p></span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='mso-list:l1 level1 lfo2;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Proven
     Full-Stack Expertise:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Extensive experience in both front-end
     development (JavaScript, React/TypeScript, Google Chrome Extension
     ecosystem) and back-end development (Node.js, <span class=SpellE>Supabase</span>).<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l1 level1 lfo2;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>AI
     and Data Privacy:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Strong understanding of AI technologies and data
     privacy best practices, with experience integrating AI models into
     production systems.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l1 level1 lfo2;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Leadership
     and Mentorship:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Demonstrated ability to lead, manage, and mentor
     a team of engineers, fostering an environment of growth and technical
     excellence.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l1 level1 lfo2;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Complex
     Integrations and Architecture:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Experience managing complex third-party
     integrations, in-house APIs, and contributing to the scalability and
     reliability of a complex architecture.<o:p></o:p></span></li>
 <li class=MsoNormal style='mso-list:l1 level1 lfo2;tab-stops:list .5in left 197.6pt'><b><span
     sans-serif;mso-ansi-language:EN-US'>Problem-Solving
     and Innovation:</span></b><span sans-serif;
     mso-ansi-language:EN-US'> Ability to navigate the challenges of a
     fast-paced startup environment, with a strong focus on delivering
     innovative solutions that drive business success.<o:p></o:p></span></li>
</ul>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Location</span></b><span
style='font-size:24.0pt;line-height:116%;font-family:"Calibri Light",sans-serif;
mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><span sans-serif;
mso-ansi-language:EN-US'>Gaya is a remote-first company, offering the
flexibility to work from anywhere.<o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Reporting
Structure</span></b><span style='font-size:24.0pt;line-height:116%;font-family:
"Calibri Light",sans-serif;mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><span sans-serif;
mso-ansi-language:EN-US'>You will report directly to the CTO, playing a pivotal
role in the strategic direction and technological advancements of the company.<o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span style='font-size:24.0pt;
line-height:116%;font-family:"Calibri Light",sans-serif;mso-ansi-language:EN-US'>Join
Us</span></b><span style='font-size:24.0pt;line-height:116%;font-family:"Calibri Light",sans-serif;
mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><span sans-serif;
mso-ansi-language:EN-US'>At Gaya, you'll have the chance to work alongside a
team led by Stanford MBA graduates, offering ample exposure and learning
opportunities for those who love a challenge and are not afraid of ambiguity.
If you can embrace entrepreneurship and have a passion for leadership,
innovation, and a desire to be part of a transformative journey, we'd love to
hear from you.<o:p></o:p></span></p>

<p class=MsoNormal style='tab-stops:197.6pt'><b><span sans-serif;
mso-ansi-language:EN-US'>If interested, please reach out with your resume to
jp@gaya.ai</span></b><span sans-serif;
mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal><span sans-serif;
mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>

</div>`
    }, */

    {
        title : "QA Engineer",
        description : `<h1><strong> QA Engineer</strong></h1>
        </br>
        <h2><strong>Role Summary</strong></h2>
        <p>We're seeking a QA Engineer with a knack for meticulous testing and a passion for quality. This role is pivotal in ensuring our products, including the Super Copy and Super Paste features of our browser extension and our client API, perform flawlessly. You will be instrumental in building and maintaining testing frameworks, employing RPA-like solutions to identify and resolve bugs swiftly. This position offers the opportunity to grow into a full-time role, working closely with our development team and engaging with customer feedback to refine and enhance product quality continuously.</p>
        <h2><strong>Responsibilities</strong></h2>
        <ul>
        <li>Develop and execute comprehensive test plans and cases for our front-end stack (JavaScript, React/TypeScript) and back-end servers (Node, Python) to ensure product quality.</li>
        <li>Design and implement testing frameworks and RPA-like solutions for automated testing, focusing on our Super Copy and Super Paste functionalities across various web environments.</li>
        <li>Regularly perform QA on our client API, ensuring its reliability and performance.</li>
        <li>Collaborate with the development team to identify, report, and troubleshoot bugs, working closely to develop solutions that enhance product stability and performance.</li>
        <li>Participate in customer feedback calls to gather insights on user experience and product issues, translating feedback into actionable quality improvements.</li>
        <li>Stay abreast of new testing tools and methodologies to continuously improve the QA process.</li>
        </ul>
        <h2><strong>Ideal Candidate Profile</strong></h2>
        <ul>
        <li>Proven experience in QA engineering, with a strong background in creating unit tests for both front-end and back-end systems.</li>
        <li>Familiarity with JavaScript, React/TypeScript, Node, and Python environments.</li>
        <li>Experience in designing and implementing automated testing frameworks and RPA-like solutions to efficiently identify and resolve bugs.</li>
        <li>Ability to work collaboratively with cross-functional teams, incorporating user feedback into the QA process.</li>
        <li>Strong communication skills and willingness to engage in customer feedback sessions to drive product improvements.</li>
        <li>Flexible to work  (20 hours a week) with the potential to transition to full-time based on performance and company needs.</li>
        </ul>
        <p>&nbsp;</p>
        <h2><strong>Location</strong></h2>
        <p>Gaya is a remote-first company, offering the flexibility to work from anywhere.</p>
        <h2><strong>Reporting Structure</strong></h2>
        <p>You will report directly to the CTO, playing a critical role in maintaining the high quality and reliability of Gaya's products and contributing to the overall success of the company.</p>
        <h2><strong>Join Us</strong></h2>
        <p>If you're passionate about ensuring the highest quality of software products and thrive in a fast-paced, innovative environment, we'd love to have you on our team. Your work will directly impact the user experience of our products, helping us achieve our mission of simplifying insurance processes through technology.</p>
        <p>If interested, please reach out with your resume to <span style="color: #EF5A24;">jp@gaya.ai</span></p>
        <p>&nbsp;</p>
        
        `
    },


    {
        title : "AI Engineer",
        description : `<h1><strong>AI Engineer</strong></h1>
    <br>
    <h2><strong>Role Summary</strong></h2>
    <p>As an AI Engineer at Gaya, you will be a critical contributor to the development and refinement of our AI-driven solutions. Working under the guidance of our CTO, you will focus on fine-tuning large language models (LLMs), benchmarking model performance, and advancing computer vision capabilities. You will also play a key role in training models using feedback and corrections generated from our users. Experience with Robotic Process Automation (RPA) and a strong understanding of webpage DOM structures is highly preferred. Proficiency in Python is required, and experience with web development using Node.js and React.js is a strong bonus.</p>
    <h2><strong>Responsibilities</strong></h2>
    <ul>
        <li><strong>Model Fine-Tuning:</strong> Assist in fine-tuning large language models (LLMs) to optimize performance for specific use cases.</li>
        <li><strong>Benchmarking & Performance Evaluation:</strong> Help benchmark and evaluate the performance of AI models, identifying opportunities for improvement.</li>
        <li><strong>Computer Vision:</strong> Contribute to the development and enhancement of computer vision models to drive automation and innovation.</li>
        <li><strong>Training Models with User Feedback:</strong> Implement workflows for training models based on user feedback and corrections, ensuring continuous improvement of our AI systems.</li>
        <li><strong>Data Extraction:</strong> Support AI-driven data extraction from documents, following best practices to ensure high accuracy and efficiency.</li>
        <li><strong>RPA Integration:</strong> Collaborate with the team to integrate AI solutions with RPA technologies, with a focus on interacting with webpage DOM structures.</li>
        <li><strong>AI Workflows & Integration:</strong> Work closely with AI workflows, systems, and endpoints, ensuring seamless integration into our product offerings.</li>
        <li><strong>Cross-Functional Collaboration:</strong> Collaborate with engineers, product managers, and designers to ensure alignment of AI projects with business goals.</li>
        <li><strong>Tooling/Web Development:</strong> Contribute to web development tasks using Node.js and React.js to support ML ops tooling.</li>
    </ul>
    <h2><strong>Ideal Candidate Profile</strong></h2>
    <ul>
        <li><strong>AI Expertise:</strong> Proficiency in AI model development, including fine-tuning LLMs, computer vision, and working with machine learning techniques.</li>
        <li><strong>Python Proficiency:</strong> Strong skills in Python, with experience building and optimizing AI models.</li>
        <li><strong>RPA Experience (Preferred):</strong> Experience with RPA technologies and a deep understanding of webpage DOM structures is highly preferred.</li>
        <li><strong>Data Extraction:</strong> Experience in implementing AI-driven data extraction solutions, following best practices.</li>
        <li><strong>Web Development (Bonus):</strong> Familiarity with Node.js and some experience in React.js, and the ability to interact with database systems like Supabase IDK is a plus.</li>
    </ul>
    <h2><strong>Core Tech Stack</strong></h2>
    <ul>
        <li>Javascript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>Python</li>
        <li>PostgreSQL</li>
    </ul>
    <h2><strong>Join Us</strong></h2>
    <p>At Gaya, you’ll have the chance to work alongside a team led by Stanford MBA graduates, offering ample exposure and learning opportunities for those who love a challenge and are not afraid of ambiguity. We'd love to hear from you if you can embrace entrepreneurship and have a passion for leadership and innovation, as well as a desire to be part of a transformative journey.</p></br></br>`
    },

    {
        title : "AI/ML Founding Engineer",
        description : `<h1><strong>AI/ML Founding Engineer</strong></h1>
    <br>
    <h2><strong>Role Summary</strong></h2>
    <p>As the AI/ML Founding Engineer at Gaya, you will be at the forefront of developing and refining our AI-driven solutions. You will take on a critical role in fine-tuning large language models (LLMs), benchmarking model performance, and advancing computer vision capabilities. In addition, you will lead the training of models using feedback and corrections generated from our users. Experience with Robotic Process Automation (RPA) and a deep understanding of webpage DOM structures are highly preferred. You will also be a leader in our growing team, driving AI innovation and setting the technical direction for the company.</p>
    
    <h2><strong>Responsibilities</strong></h2>
    <ul>
        <li><strong>Data Extraction:</strong> Implement and optimize AI-driven data extraction techniques from documents, following best practices to ensure accuracy, efficiency, and scalability.</li>
        <li><strong>Model Fine-Tuning:</strong> Lead the fine-tuning of large language models (LLMs) to optimize performance and align with specific use cases.</li>
        <li><strong>Benchmarking & Performance Evaluation:</strong> Regularly benchmark and evaluate the performance of AI models, implementing improvements to ensure high-quality outputs.</li>
        <li><strong>Computer Vision:</strong> Develop and enhance computer vision models to drive extraction, automation, and innovation in our product offerings.</li>
        <li><strong>Training Models with User Feedback:</strong> Design and implement workflows for training models based on user feedback and corrections, ensuring our models continually improve over time.</li>
        <li><strong>RPA Integration:</strong> Leverage your knowledge of RPA technologies to develop AI solutions that integrate seamlessly into existing workflows, with a strong focus on automating interactions with webpage DOM structures.</li>
        <li><strong>Team Leadership:</strong> Provide leadership and mentorship to the engineering team, fostering a collaborative and high-performing environment.</li>
        <li><strong>Cross-functional collaboration:</strong> Work closely with product managers, engineers, and designers to ensure AI initiatives align with broader business goals and deliver impactful results.</li>
        <li><strong>Scalability & Infrastructure:</strong> Develop scalable AI systems that can handle increasing amounts of data and user feedback efficiently. Also help Gaya develop the core infrastructure to develop its core building blocks into tooling for modern web agents.</li>
    </ul>

    <h2><strong>Ideal Candidate Profile</strong></h2>
    <ul>
        <li><strong>Extensive AI Expertise:</strong> Proven experience in fine-tuning LLMs, training AI models, comfort with embedding/RAG systems, and applying machine learning techniques to solve complex problems.</li>
        <li><strong>Computer Vision Proficiency:</strong> Hands-on experience with developing and optimizing computer vision models.</li>
        <li><strong>RPA Experience (Preferred):</strong> Strong background in RPA technologies, with an understanding of how AI and RPA can be combined to create powerful automation solutions.</li>
        <li><strong>DOM Structure Knowledge:</strong> Deep understanding of webpage DOM structures and experience working with them in the context of automation or AI.</li>
        <li><strong>Leadership Experience:</strong> Demonstrated ability to lead and mentor engineering teams, guiding them through challenging AI projects.</li>
        <li><strong>AI Workflow Design:</strong> Ability to design workflows for training AI models based on user feedback, ensuring continuous learning and improvement.</li>
    </ul>

    <h2><strong>Core Tech Stack</strong></h2>
    
    <ul>
        <li>Javascript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>Python</li>
        <li>PostgreSQL</li>
    </ul>
    
    <h2><strong>Reporting Structure</strong></h2>
    <p>You will be working and reporting directly to our two co-founders: Carl Ziade and Jean-Pierre Vertil - playing a pivotal role in the company's strategic direction and technological advancements.</p>

    <h2><strong>Join Us</strong></h2>
    <p>At Gaya, you’ll have the chance to work alongside a team led by Stanford MBA graduates, offering ample exposure and learning opportunities for those who love a challenge and are not afraid of ambiguity. We'd love to hear from you if you can embrace entrepreneurship and have a passion for leadership and innovation, as well as a desire to be part of a transformative journey.</p></br></br>`
    },

    {
        title : "Lead Engineer",
        description : `<h1><strong>Lead Engineer</strong></h1>
    <br>
    <h2><strong>Role Summary</strong></h2>
    <p>As a Lead Engineer at Gaya, you will take on a crucial leadership role in shaping our platform's front-end and back-end architecture. You will be instrumental in driving the development of our browser extension, client portal, and backend services, ensuring a seamless and secure user experience. This role offers a unique opportunity to lead a high-performing team, manage complex integrations, and contribute to developing cutting-edge AI technologies within a fast-paced startup environment. Additionally, you will play a key role in recruiting and building up our engineering team, with a preference for candidates with experience developing RPA (Robotic Process Automation) solutions.</p>
    
    <h2><strong>Responsibilities</strong></h2>
    <ul>
        <li><strong>Technical Leadership:</strong> Oversee the end-to-end development and enhancement of our browser extension within the Google Chrome Extension ecosystem using JavaScript, React/TypeScript, and our Node.js-based backend hosted on Supabase.</li>
        <li><strong>Full-Stack Development:</strong> Contribute to the design and implementation of our back-end services, including managing our in-house APIs, third-party data integrations, and ensuring the scalability and reliability of our architecture.</li>
        <li><strong>AI Integration and Privacy:</strong> Lead the implementation and integration of AI technologies, proprietary models, and third-party solutions, with a strong emphasis on data privacy and ethical AI practices.</li>
        <li><strong>Team Management:</strong> Mentor and manage a growing team of engineers, fostering a culture of collaboration, continuous improvement, and technical excellence.</li>
        <li><strong>Recruitment & Team Building:</strong> Play a key role in recruiting and assessing new engineering talent, helping Gaya grow its engineering team by identifying and attracting top candidates.</li>
        <li><strong>Code Quality and Reviews:</strong> Conduct thorough code reviews, ensuring that both front-end and back-end code is maintainable, scalable, and secure. Advocate for best practices across the entire technology stack.</li>
        <li><strong>Cross-Functional Collaboration:</strong> Work closely with cross-functional teams to ensure that engineering efforts align with business goals and deliver high-impact results.</li>
        <li><strong>Architectural Decision-Making:</strong> Play a key role in the architectural decisions that shape the future of our technology, balancing the needs for innovation with the demands of a fast-moving startup environment.</li>
        <li><strong>Ensure Renowned Compliance:</strong> Gaya will soon be working to become SOC2 compliant among other certifications. You will be tasked to carry the team to these requirements.</li>
    </ul>
    
    <h2><strong>Ideal Candidate Profile</strong></h2>
    <ul>
        <li><strong>Seniority:</strong> At least ten years of development experience building production-grade code.</li>
        <li><strong>Proven Full-Stack Expertise:</strong> Extensive experience in both front-end development (JavaScript, React/TypeScript, Google Chrome Extension ecosystem) and back-end development (Node.js, Supabase).</li>
        <li><strong>RPA Experience (Preferred):</strong> A strong background in building RPA (Robotic Process Automation) solutions is highly desirable.</li>
        <li><strong>AI and Data Privacy:</strong> Strong understanding of AI technologies and data privacy best practices, with experience integrating AI models into production systems.</li>
        <li><strong>Leadership and Mentorship:</strong> Demonstrated ability to lead, manage, and mentor a team of engineers, fostering an environment of growth and technical excellence.</li>
        <li><strong>Recruitment & Team Building:</strong> Experience recruiting and building engineering teams, with the ability to assess technical talent and culture fit.</li>
        <li><strong>Complex Integrations and Architecture:</strong> Experience managing complex third-party integrations, in-house APIs, and contributing to the scalability and reliability of a complex architecture.</li>
        <li><strong>Problem-Solving and Innovation:</strong> Ability to navigate the challenges of a fast-paced startup environment, with a strong focus on delivering innovative solutions that drive business success.</li>
    </ul>

    <h2><strong>Core Tech Stack</strong></h2>
    <ul>
        <li>JavaScript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>Python</li>
        <li>PostgreSQL</li>
    </ul>
    
    <h2><strong>Reporting Structure</strong></h2>
    <p>You will report directly to the CTO, playing a pivotal role in the company's strategic direction and technological advancements.</p>
    
    <h2><strong>Join Us</strong></h2>
    <p>At Gaya, you’ll have the chance to work alongside a team led by Stanford MBA graduates, offering ample exposure and learning opportunities for those who love a challenge and are not afraid of ambiguity. We'd love to hear from you if you can embrace entrepreneurship and have a passion for leadership and innovation, as well as a desire to be part of a transformative journey.</p>
</div></br></br>`

    },

    {
        title : "Senior Backend Engineer",
        description : `<h1><strong>Senior Backend Engineer</strong></h1>
    <br>
    <h2><strong>Role Summary</strong></h2>
    <p>As a Senior Backend Engineer at Gaya, you will be critical in enhancing and scaling our backend systems. You will be responsible for improving our API infrastructures, optimizing core algorithms, ensuring system reliability, and implementing robust unit testing across our tech stack. Your expertise will also support our migration to a more robust cloud provider. Experience with Robotic Process Automation (RPA) solutions is strongly preferred.</p>
    
    <h2><strong>Responsibilities</strong></h2>
    <ul>
        <li><strong>Backend Development:</strong> Lead the development and optimization of our backend services, focusing on both internal and external API infrastructures.</li>
        <li><strong>Unit Testing Implementation:</strong> Establish and implement comprehensive unit testing across the entire backend tech stack, setting a playbook for the engineering team.</li>
        <li><strong>Algorithm Optimization:</strong> Enhance the efficiency of our core algorithms to improve speed, reliability, and security.</li>
        <li><strong>Cloud Migration Support:</strong> Assist in migrating our backend systems from Supabase to a more robust cloud provider, ensuring a smooth transition with minimal downtime.</li>
        <li><strong>System Scalability and Reliability:</strong> Design and implement solutions that ensure our backend systems can scale effectively while maintaining high availability.</li>
        <li><strong>Collaboration:</strong> Work closely with front-end engineers, DevOps, and other stakeholders to deliver cohesive and efficient solutions.</li>
        <li><strong>AI Integration:</strong> Comfortably work with AI workflows, systems, and endpoints to integrate advanced features into our backend services.</li>
    </ul>
    
    <h2><strong>Ideal Candidate Profile</strong></h2>
    <ul>
        <li><strong>Seniority:</strong> At least seven years of development experience building production-grade code.</li>
        <li><strong>Extensive Backend Expertise:</strong> Strong proficiency in Node.js and experience with cloud-based backend services.</li>
        <li><strong>RPA Experience (Preferred):</strong> A strong background in building RPA (Robotic Process Automation) solutions is highly desirable.</li>
        <li><strong>Testing and Quality Assurance:</strong> Proven experience implementing unit testing frameworks and establishing best practices for backend systems.</li>
        <li><strong>API Development:</strong> Expertise in designing, building, and optimizing internal and external APIs.</li>
        <li><strong>Algorithm Optimization:</strong> Demonstrated ability to optimize algorithms for performance, reliability, and security.</li>
        <li><strong>Cloud Infrastructure Knowledge:</strong> Experience migrating backend services to robust cloud providers and knowledge of cloud infrastructure best practices.</li>
        <li><strong>Collaborative Team Player:</strong> Excellent communication skills and a proactive approach to teamwork and problem-solving.</li>
    </ul>

    <h2><strong>Core Tech Stack</strong></h2>
    <ul>
        <li>JavaScript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>Python</li>
        <li>PostgreSQL</li>
    </ul>
    
    <h2><strong>Reporting Structure</strong></h2>
    <p>You will report directly to our Lead Engineer as well as our CTO, playing a pivotal role in the company's strategic direction and technological advancements.</p>
    
    <h2><strong>Join Us</strong></h2>
    <p>At Gaya, you’ll be part of a dynamic team led by Stanford MBA graduates, offering ample exposure and learning opportunities for those who love a challenge and are not afraid of ambiguity. If you’re passionate about backend engineering innovation and eager to contribute significantly to a transformative journey, we’d love to hear from you.</p></br></br> `
    },

    {
        title : "Senior Front-End Engineer",
        description : `<h1><strong>Senior Front-End Engineer</strong></h1>
    <br>
    <h2><strong>Role Summary</strong></h2>
    <p>As a Senior Front-End Engineer at Gaya, you will play a pivotal role in shaping the user experience of our platform. Leveraging your extensive experience in building browser extensions, you will inherit and master our existing codebase, significantly contributing to its enhancement and scalability. You will collaborate closely with cross-functional teams, working comfortably with AI workflows, systems, and endpoints. Experience with Robotic Process Automation (RPA) solutions is strongly preferred.</p>
    
    <h2><strong>Responsibilities</strong></h2>
    <ul>
        <li><strong>Front-End Development:</strong> Lead the development and optimization of our browser extension using JavaScript, React/TypeScript, and other relevant technologies.</li>
        <li><strong>Codebase Mastery:</strong> Quickly understand and take ownership of our existing codebase, identifying areas for improvement and implementing necessary enhancements.</li>
        <li><strong>User Experience Enhancement:</strong> Improve the reliability and feel of the browser extension, optimizing for a stellar user experience.</li>
        <li><strong>Modularity and Scalability:</strong> Refactor hard-coded workflows into modular components to enhance scalability and maintainability.</li>
        <li><strong>Automation Testing:</strong> Create and implement automated testing workflows to ensure code quality and reliability.</li>
        <li><strong>Collaboration:</strong> Work closely with other engineers, product managers, and stakeholders to align on project goals and deliverables.</li>
        <li><strong>AI Integration:</strong> Comfortably integrate AI workflows, systems, and endpoints into the front-end development process.</li>
    </ul>
    
    <h2><strong>Ideal Candidate Profile</strong></h2>
    <ul>
        <li><strong>Seniority:</strong> At least seven years of development experience building production-grade code.</li>
        <li><strong>Extensive Browser Extension Experience:</strong> Proven experience in building and optimizing browser extensions, particularly within the Google Chrome ecosystem.</li>
        <li><strong>Front-End Expertise:</strong> Strong proficiency in JavaScript, React/TypeScript, and modern front-end development practices.</li>
        <li><strong>RPA Experience (Preferred):</strong> Familiarity with building RPA solutions is highly desirable.</li>
        <li><strong>Codebase Leadership:</strong> Ability to inherit an existing codebase, quickly master it, and drive significant contributions.</li>
        <li><strong>AI Proficiency:</strong> Comfortable working with AI workflows, systems, and integrating AI endpoints.</li>
    </ul>

    <h2><strong>Core Tech Stack</strong></h2>
    <ul>
        <li>JavaScript</li>
        <li>React</li>
        <li>Node.js</li>
        <li>Python</li>
        <li>PostgreSQL</li>
    </ul>
    
    <h2><strong>Reporting Structure</strong></h2>
    <p>You will report directly to our Lead Engineer as well as our CTO, playing a pivotal role in the company's strategic direction and technological advancements.</p>
    
    <h2><strong>Join Us</strong></h2>
    <p>At Gaya, you’ll have the chance to work alongside a team led by Stanford MBA graduates, offering ample exposure and learning opportunities for those who love a challenge and are not afraid of ambiguity. We'd love to hear from you if you can embrace entrepreneurship and have a passion for leadership and innovation, as well as a desire to be part of a transformative journey.</p></br></br>`
    }




    

]


export default CareersData



/** {
        title : "Senior Front-End Engineer",
        description : `<h1><strong>Senior Front-End Engineer</strong></h1>
        </br>
        <h2><strong>Role Summary</strong></h2>
        <p>As a Senior Front-End Engineer at Gaya, you will play a pivotal role in developing and enhancing our browser extension and client portal. Your expertise will not only shape the user experience but also contribute to the core functionality that sets our products apart. This is a unique opportunity to be part of a dynamic, high-performing team dedicated to tackling complex technical and organizational challenges within a fast-paced startup environment.</p>
        <h2><strong>Responsibilities</strong></h2>
        <ul>
        <li>Lead the development and enhancement of our browser extension within the Google Chrome Extension ecosystem, utilizing JavaScript, React/TypeScript.</li>
        <li>Design and develop a user-friendly client portal for easy access to saved records and management of user access.</li>
        <li>Conduct thorough code reviews for junior team members, ensuring high-quality, maintainable code.</li>
        <li>Collaborate closely with cross-functional teams to meet project timelines and deliver data-driven results.</li>
        <li>Embrace and advocate for best practices in front-end development and contribute to a culture of continuous improvement.</li>
        </ul>
        <h2><strong>Ideal Candidate Profile</strong></h2>
        <ul>
        <li>Proven experience in building browser extensions and production-grade software.</li>
        <li>Strong proficiency in JavaScript, React/TypeScript, and familiarity with the Google Chrome Extension ecosystem.</li>
        <li>Excellent teamwork skills and a track record of working within high-performing, healthy, and sustainable teams.</li>
        <li>Exceptional code review and debugging skills, with a focus on maintainability and performance.</li>
        <li>Ability to thrive in a fast-moving startup environment, with a results-driven mindset.</li>
        </ul>
        <p>&nbsp;</p>
        <h2><strong>Location</strong></h2>
        <p>Gaya is a remote-first company, offering the flexibility to work from anywhere.</p>
        <h2><strong>Reporting Structure</strong></h2>
        <p>You will report directly to the CTO, playing a crucial role in the company's technological advancements and success.</p>
        <h2><strong>Join Us</strong></h2>
        <p>At Gaya, we're not just building technology; we're creating a new way to navigate the complexities of the insurance industry. If you're a visionary engineer with a passion for innovation and a desire to be part of a transformative journey, we'd love to hear from you.</p>
        <p>&nbsp;</p>
        <p>If interested, please reach out with your resume to <span style="color: #EF5A24;">jp@gaya.ai</span></p>
        <p>&nbsp;</p>`
    }, */