/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import useWindowDimensions from "../tools/dimensions";
import { Button, Flex } from "antd";
import { Divider } from "antd";
import Logo1 from "../assets/images/gaya_logo-white.png";
import Logo2 from "../assets/images/logo2w.png";
import twitter from "../assets/images/x.png";
import linkdin from "../assets/images/in.png";

import arrowr from "../assets/images/arrow_circle.png";

const FooterComponent = ({ screenWidth }) => {
  return (
    <div>
      <div
        style={{
          //width: "1340px",
          width: "92%",
          height: "112px",
          backgroundColor: "#24201E",
          marginTop: 70,
          borderTopRightRadius: 70,
          borderBottomRightRadius: 70,
          paddingLeft: 140,
          paddingRight: 200,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            height: "112px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "138px",
              height: "71px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={Logo1} style={{ height: "70px" }} />
            {/* <img
              src={Logo2}
              style={{ width: "74px", height: "21px", marginTop: 15 }}
            /> */}
          </div>

          <div
            style={{
              marginTop: 15,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "400px",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                width: "173px",
                height: "19px",
                fontSize: "24px",
                color: "white",
              }}
            >
              (650) 284-3767
            </p>
            <p
              style={{
                width: "140px",
                height: "19px",
                fontSize: "24px",
                color: "white",
              }}
            >
              hey@gaya.ai
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "",
          marginTop: 50,
          width: "100%",
          height: "112px",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: "300px",
            height: "265px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          <div
            style={{
              width: "80px",
              height: "165px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href="https://twitter.com/getgaya"
            >
              <img src={twitter} style={{ height: "34px" }} />
            </a>
            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href="https://www.linkedin.com/company/gaya-ai/"
            >
              <img src={linkdin} style={{ width: "34px" }} />
            </a>
          </div>

          <div
            style={{
              width: "69px",
              height: "165px",
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{
                fontSize: "15px",
                lineHeight: "20.49px",
                color: "white",
                textDecoration: "none",
              }}
              href="/"
            >
              Home
            </a>
            <a
              style={{
                fontSize: "15px",
                lineHeight: "20.49px",
                color: "white",
                textDecoration: "none",
              }}
              //href="/blog"
              href="https://blog.gaya.ai/blog" /* /blog */
              target="_blank"
             
            >
              Blog
            </a>
            <a
              style={{
                fontSize: "15px",
                lineHeight: "20.49px",
                color: "white",
                textDecoration: "none",
              }}
              href="/careers"
            >
              Careers
            </a>
            <a
              style={{
                fontSize: "15px",
                lineHeight: "20.49px",
                color: "white",
                textDecoration: "none",
              }}
              href="/developers"
            >
              Developers
            </a>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "352px", height: "194px", textAlign: "left" }}>
            <h1 style={{ fontSize: "84px", color: "white" }}>
              Let’s Get Started
            </h1>
          </div>

          <a
            style={{
              textDecoration: "none",
            }}
            href="https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
            target="_blank"
          >
            <img
              src={arrowr}
              style={{ width: "100px", height: "100px", marginTop: 90 }}
            />
          </a>
        </div>
      </div>

      {/*Divider */}
      <div
        style={{
          width: "80%",
          alignItems: "center",
          margin: "0 auto",
          marginTop: 150,
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
            color: "#373737",
            borderColor: "#373737",
          }}
        />
      </div>

      <div
        style={{
          width: "80%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            width: "320px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <a
            style={{
              fontSize: "12px",
              color: "white",
              width: "112px",
              textDecoration: "none",
            }}
            target="_blank"
            href="https://portal.gaya.ai/terms"
          >
            Terms & Conditions
          </a>
          <a
            style={{
              fontSize: "12px",
              color: "white",
              width: "99px",
              textDecoration: "none",
            }}
            target="_blank"
            href="/policy/fulfillment-policy"
          >
            Fulfillment Policy
          </a>
          <a
            style={{
              fontSize: "12px",
              color: "white",
              width: "79px",
              textDecoration: "none",
            }}
            target="_blank"
            href="https://portal.gaya.ai/privacy"
          >
            Privacy Policy
          </a>
        </div>

        <div>
          <p style={{ fontSize: "12px", color: "white" }}>
            © Gaya 2024 - All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

const FooterComponentMobile = ({ screenWidth }) => {
  return (
    <div style={{ width: "100%", height: "640px", backgroundColor: "#2B2928" }}>
      <div
        style={{
          width: "355px",
          height: "106px",
          backgroundColor: "#24201E",
          marginTop: 70,
          borderTopRightRadius: 70,
          borderBottomRightRadius: 70,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            //justifyContent: "space-around",
            paddingTop: 10,
            width: "355px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              width: "90px",
              height: "46.3px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={Logo1} style={{ height: "45px" }} />
          </div>

          <div
            style={{
              marginTop: 15,
              display: "flex",
              alignItems: "center",
              width: "500px",
              //justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                width: "173px",
                height: "19px",
                fontSize: "18px",
                color: "white",
              }}
            >
              (650) 284-3767
            </p>
            <p
              style={{
                width: "140px",
                height: "19px",
                fontSize: "18px",
                color: "white",
              }}
            >
              hey@gaya.ai
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 50,
        }}
      >
        <div style={{ width: "57px", height: "92px" }}>
          <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
            onClick={() => {
              window.location = "/";
            }}
          >
            Home
          </p>
          <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
            onClick={() => {
              //window.location = "/blog";
              window.open("https://blog.gaya.ai/blog", "_blank");
            }}
          >
            Blog
          </p>
          {/* <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
          >
            Careers
          </p> */}
        </div>

        <div style={{ width: "81px", height: "92px" }}>
          <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
            onClick={() => {
              window.location = "/careers";
            }}
          >
            Careers
          </p>
          <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
            onClick={() => {
              window.location = "/developers";
            }}
          >
            Developers
          </p>
          {/*  <p
            style={{ fontSize: "15px", lineHeight: "20.49px", color: "white" }}
          >
            Contact
          </p> */}
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          display: "flex",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            width: "70px",
            height: "24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <img
            onClick={() => {
              window.open("https://twitter.com/getgaya", "_blank");
            }}
            src={twitter}
            style={{ height: "28px" }}
          />

          <img
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/gaya-ai/",
                "_blank"
              );
            }}
            src={linkdin}
            style={{ height: "28px" }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "335px",
          marginLeft: 25,
          marginTop: 40,
        }}
      >
        <div style={{ width: "230px", textAlign: "left" }}>
          <h1 style={{ fontSize: "52px", color: "white" }}>
            Let’s Get Started
          </h1>
        </div>

        <div>
          <img
            src={arrowr}
            style={{ width: "70.8px", height: "70.8px", marginTop: 40 }}
            onClick={() => {
              window.open(
                "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                "_blank"
              );
            }}
          />
        </div>
      </div>
      <div
        style={{ width: screenWidth, alignItems: "center", margin: "0 auto" }}
      >
        <Divider
          style={{
            borderWidth: 2,
            color: "#373737",
            borderColor: "#373737",
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "350px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{ fontSize: "12px", color: "white", width: "112px" }}
            onClick={() => {
              window.open("https://portal.gaya.ai/terms", "_blank");
            }}
          >
            Terms & Conditions
          </p>


          <p
            style={{ fontSize: "12px", color: "white", width: "100px" }}
            onClick={() => {
              window.open("/policy/fulfillment-policy", "_blank");
            }}
          >
            Fulfillment Policy
          </p>



          <p
            style={{ fontSize: "12px", color: "white", width: "79px" }}
            onClick={() => {
              window.open("https://portal.gaya.ai/privacy", "_blank");
            }}
          >
            Privacy Policy
          </p>


        </div>
        <div>
          <p style={{ fontSize: "12px", color: "white" }}>
            © Gaya 2022 - All rights reserved
          </p>
        </div>
      </div>
      {/* 
            <div style={{ display: "flex", flexDirection: "row", alignItems: "", marginTop: 50, width: "1340px", height: "112px", justifyContent: "space-around" }}>
                <div style={{ width: "300px", height: "265px", display: 'flex', flexDirection: "row", justifyContent: "space-between", marginTop: 15 }}>
                    <div style={{ width: "80px", height: "165px", display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                        <img src={twitter} style={{ width: '24px', height: "24px", }} />
                        <img src={linkdin} style={{ width: '24px', height: "24px", }} />
                    </div>

                    <div style={{ width: "69px", height: "165px", textAlign: "left" }}>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>Home</p>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>Solutions</p>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>About</p>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>Careers</p>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>Blog</p>
                        <p style={{ fontSize: '15px', lineHeight: "20.49px", color: "white" }}>Contact</p>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row", }}>
                    <div style={{ width: "352px", height: "194px", textAlign: 'left' }}>
                        <h1 style={{ fontSize: "84px", color: "white" }}>Let’s Get Started</h1>
                    </div>

                    <div>
                        <img src={arrowr} style={{ width: '100px', height: "100px", marginTop: 90 }} />
                    </div>
                </div>
            </div>



           
            <div style={{ width: screenWidth, alignItems: "center", margin: '0 auto', marginTop: 150 }}>
                <Divider style={{
                    borderWidth: 2,
                    color: "#373737",
                    borderColor: '#373737'
                }} />
            </div>

            <div style={{ width: screenWidth, alignItems: "center", display: "flex", flexDirection: "row", justifyContent: 'space-between', margin: '0 auto', }}>
                <div style={{ width: "250px", display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                    <p style={{ fontSize: "12px", color: "white", width: "112px" }}>Terms & Conditions</p>
                    <p style={{ fontSize: "12px", color: "white", width: "79px" }}>Privacy Policy</p>
                </div>

                <div >
                    <p style={{ fontSize: "12px", color: "white" }}>© Gaya 2022 - All rights reserved</p>
                </div>


            </div> */}
    </div>
  );
};

export default function Footer() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);
  return (
    <div
      style={{
        //width: "100%",
        height: "573px",
        backgroundColor: "#2B2928",
        //display: "flex",
        //flexDirection: "column",
        // marginTop: 30,
        paddingTop: 10,
      }}
    >
      {isMobile ? (
        <FooterComponentMobile screenWidth={screenWidth} />
      ) : (
        <FooterComponent screenWidth={screenWidth} />
      )}
    </div>
  );
}
