import { useEffect, useState } from "react";
import NavigationBar from "../../navigation/navbar";
import useWindowDimensions from "../../tools/dimensions";
import Footer from "../../navigation/footer";
import CareersData from "../../datas/careers";

const Detail = () => {
  const [career, setCareer] = useState(undefined)
  let decodedTitle

  useEffect(() => {
    let currentUrl = window.location.href;
    let parts = currentUrl.split("/");
    const title = parts[parts.length - 1];
    const decodedTitle = decodeURIComponent(title.replace("**", "/"))
    const career_ = CareersData.find(obj => obj.title.toLowerCase() === decodedTitle);
    console.log(career_)
    setCareer(career_)
  }, []);
  return (


    <div
      style={{
        textAlign: "left",
        width: "768px",
        marginTop: 60,
        marginBottom: 20
      }}

      dangerouslySetInnerHTML={{ __html: `${career ? career.description : ""}` }}

    />
  )
};
{/* <div style={{ width: "768px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 150,
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "543px", height: "59px", textAlign: "left" }}>
          <h1 style={{ fontSize: "48px", color: "#2B2928" }}>
            Lead Software Engineer
          </h1>
          <h3
            style={{
              width: "116px",
              height: "29px",
              fontSize: "18px",
              color: "#656565",
            }}
          >
            USA - Remote
          </h3>
          <p
            style={{
              width: "768px",
              height: "116px",
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            Success in this role requires moving quickly to build and ship
            production-ready code iteratively. You will work closely with both
            the CTO and CEO. If you’re scrappy, full of enthusiasm, a doer, and
            aspire to launch your own startup someday, this role is for you. We
            will give you much exposure to growing an early-stage startup.
          </p>
        </div>
      </div>

      <div
        style={{
          height: "285px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: 200,
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            color: "#2B2928",
            width: "768px",
            height: "37px",
          }}
        >
          Who we’re looking for
        </h1>
        <ul style={{ color: "#656565", lineHeight: "29.16px" }}>
          <li>
            5 years of engineering experience, working with teams focused on
            building and
          </li>
          <li>
            shipping robust, high-quality, production-ready products (Fintech or
            Insurtech is a plus)
          </li>
          <li>
            Highly proficient technical knowledge of front-end, back-end, cloud
            (AWS, Heroku), and integration technologies and best practices at
            scale. Our current stack requires proficiency in Node.JS, Web APIs,
            Javascript, TypeScript, ReactJS
          </li>
          <li>
            Successful track record of working with high-performing, healthy,
            and sustainable
          </li>
          <li>
            teams that have addressed complex technical and organizational
            challenges
          </li>
          <li>
            Ability to work in a fast-moving startup organization with
            data-driven results in mind
          </li>
        </ul>
      </div>

      <div
        style={{
          height: "314px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: 50,
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            color: "#2B2928",
            width: "768px",
            height: "37px",
          }}
        >
          Benefits & Perks
        </h1>
        <ul style={{ color: "#656565", lineHeight: "29.16px" }}>
          <li>Competitive salary and generous equity incentive plan</li>
          <li>Flexible Paid time off</li>
          <li>Health Insurance</li>
          <li>Vision Insurance</li>
          <li>FSA</li>
          <li>401 (k)</li>
          <li>Commuter benefit</li>
          <li>Technology Allowance</li>
          <li>Company retreats</li>
        </ul>
      </div>

      <div
        style={{
          height: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: 20,
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            color: "#2B2928",
            width: "768px",
            height: "37px",
          }}
        >
          If interested, please reach out with your resume to{" "}
          <span style={{ color: "#EF5A24" }}>jp@gaya.ai</span>
        </h1>
      </div>
    </div> */}
const DetailMobile = () => {
  const [career, setCareer] = useState(undefined)
  let decodedTitle

  useEffect(() => {
    let currentUrl = window.location.href;
    let parts = currentUrl.split("/");
    const title = parts[parts.length - 1];
    const decodedTitle = decodeURIComponent(title.replace("**", "/"))
    const career_ = CareersData.find(obj => obj.title.toLowerCase() === decodedTitle);
    console.log(career_)
    setCareer(career_)
  }, []);
  return (
    <div
      style={{
        textAlign: "left",
        //width: "768px",
        //padding : 30

        paddingTop : 50,
        paddingLeft : 25,
        paddingRight : 25
      }}

      dangerouslySetInnerHTML={{ __html: `${career ? career.description : ""}` }}

    />
  );
};
{/* <div style={{ width: "335px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 250,
          justifyContent: "space-around",
          height: "100px",
        }}
      >
        <div style={{ width: "335px", textAlign: "left" }}>
          <h1 style={{ fontSize: "36px", color: "#2B2928" }}>
            Lead Software Engineer
          </h1>
          <h3
            style={{
              width: "116px",
              height: "29px",
              fontSize: "18px",
              color: "#656565",
            }}
          >
            USA - Remote
          </h3>
          <p
            style={{
              width: "335px",
              height: "261px",
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            Success in this role requires moving quickly to build and ship
            production-ready code iteratively. You will work closely with both
            the CTO and CEO. If you’re scrappy, full of enthusiasm, a doer, and
            aspire to launch your own startup someday, this role is for you. We
            will give you much exposure to growing an early-stage startup.
          </p>
        </div>
      </div>
      <div
        style={{
          height: "720px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: 200,
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            color: "#2B2928",
            width: "335px",
            height: "37px",
          }}
        >
          Who we’re looking for
        </h1>
        <ul
          style={{ color: "#656565", lineHeight: "29.16px", height: "667px" }}
        >
          <li>
            5 years of engineering experience, working with teams focused on
            building and
          </li>
          <li>
            shipping robust, high-quality, production-ready products (Fintech or
            Insurtech is a plus)
          </li>
          <li>
            Highly proficient technical knowledge of front-end, back-end, cloud
            (AWS, Heroku), and integration technologies and best practices at
            scale. Our current stack requires proficiency in Node.JS, Web APIs,
            Javascript, TypeScript, ReactJS
          </li>
          <li>
            Successful track record of working with high-performing, healthy,
            and sustainable
          </li>
          <li>
            teams that have addressed complex technical and organizational
            challenges
          </li>
          <li>
            Ability to work in a fast-moving startup organization with
            data-driven results in mind
          </li>
        </ul>
      </div>
      <div
        style={{
          height: "343px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: -80,
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            color: "#2B2928",
            width: "335px",
            height: "37px",
          }}
        >
          Benefits & Perks
        </h1>
        <ul
          style={{
            color: "#656565",
            lineHeight: "29.16px",
            lineHeight: "29.16px",
            height: "290px",
          }}
        >
          <li>Competitive salary and generous equity incentive plan</li>
          <li>Flexible Paid time off</li>
          <li>Health Insurance</li>
          <li>Vision Insurance</li>
          <li>FSA</li>
          <li>401 (k)</li>
          <li>Commuter benefit</li>
          <li>Technology Allowance</li>
          <li>Company retreats</li>
        </ul>
      </div>
      <div
        style={{
          //   height: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          textAlign: "left",
          marginTop: 40,
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            color: "#2B2928",
            width: "335px",
            // height: "37px",
          }}
        >
          If interested, please reach out with your resume to{" "}
          <span style={{ color: "#EF5A24" }}>jp@gaya.ai</span>
        </h1>
      </div>{" "}
    
    </div> */}
export default function CareersDetail() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    console.log("New width : " + width);
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

  return (
    <div>
      <NavigationBar isMobile={isMobile} selected={"Careers"} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        {isMobile ? <DetailMobile /> : <Detail />}
      </div>

      <Footer />
    </div>
  );
}
