import React, { useEffect, useState } from "react";
import im1 from "../assets/images/Fulfillment Policy for Gaya-1.svg"
import im2 from "../assets/images/Fulfillment Policy for Gaya-2.svg"
import useWindowDimensions from "../tools/dimensions";
// ...




export default function FulfillmentPolicy (){

    const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      //setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      //setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

    return (
        <div style={{display : "flex", flexDirection : "column", alignItems :"center", justifyContent : "center", backgroundColor : "black", padding : !isMobile ? 60 : 25, height : "100%"}}>
            <img src={im1} alt="description of the SVG" style={{display : "flex", width : !isMobile ? "75%" : "100%", marginBottom : 10}} />
            <img src={im2} alt="description of the SVG" style={{display : "flex", width : !isMobile ? "75%" : "100%",}} />
        </div>
    );
}