/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import "../App.css";
import NavigationBar from "../navigation/navbar";
import useWindowDimensions from "../tools/dimensions";
import { useEffect, useState } from "react";
import { Button } from "antd";
import { Divider } from "antd";
import superCopy from "../assets/images/supercopy.png";
import superPaste from "../assets/images/superpaste.png";

//Partners
import zapier from "../assets/images/patners/zapier.svg";
import agencyZoom from "../assets/images/patners/agencyzoom.png";
import salt from "../assets/images/patners/salt.png";
import canopyConnect from "../assets/images/patners/canopy.png";
import riskAdvizor from "../assets/images/patners/riskAdvisor.png";
import hawksoft from "../assets/images/patners/hawksoft.png";
import cognitoForms from "../assets/images/patners/cognito.png";
import jotForm from "../assets/images/patners/jotform.png";
import nowCert from "../assets/images/patners/nowcerts.png";
import zoho from "../assets/images/patners/zoho.png";
import salesforce from "../assets/images/patners/salesforce.png";

//Investors
import asurion from "../assets/images/investors/asurion.svg";
import pear from "../assets/images/investors/pear.png";
import ramp from "../assets/images/investors/ramp.png";
import startx from "../assets/images/investors/startx.png";

import circle from "../assets/images/circle.png";
import circle2 from "../assets/images/circle2.png";

import profile from "../assets/images/profile.jpg";

import brain from "../assets/images/network-brain 1.svg";
import bot_mail from "../assets/images/bot-2 1.svg";
import bot from "../assets/images/bot.svg";

import { FaCircle } from "react-icons/fa";
import Footer from "../navigation/footer";

import ReactPlayer from "react-player";

import home_im_1 from "../assets/images/home_im_1_1_.png";
import home_im_2 from "../assets/images/home_im_1_2_.png";


import rectangleL from "../assets/images/Rectangle 7 (1).svg";

import radiusCircle from "../assets/images/radius circle.svg";

import triangle1 from "../assets/images/backgroundtrinagle.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import love1 from "../assets/images/loves/1.jpeg";
import love2 from "../assets/images/loves/2.jpeg";

import semiCircle1 from "../assets/images/semicircle.svg";

//import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';

const logos = [
  zapier,
  agencyZoom,
  salt,
  canopyConnect,
  riskAdvizor,
  hawksoft,
  cognitoForms,
  jotForm,
  nowCert,
  zoho,
  salesforce,
];

const LogoComponent = () => {
  return (
    <div>
      <div class="container mt-5 d-none d-lg-block d-xl-block ">
        <div class="row" style={{ marginTop: 100 }}>
          <div class="col">
            <img src={zapier} className="img-flud " style={{ height: 40 }} />
          </div>
          <div class="col">
            {" "}
            <img
              src={agencyZoom}
              className="img-flud "
              style={{ height: 40 }}
            />
          </div>

          <div class="col">
            {" "}
            <img src={salt} className="img-flud " style={{ height: 35 }} />
          </div>
          <div class="col">
            {" "}
            <img
              src={canopyConnect}
              className="img-flud  "
              style={{ height: 40 }}
            />
          </div>
          <div class="col">
            {" "}
            <img
              src={riskAdvizor}
              className="img-flud  "
              style={{ height: 30 }}
            />
          </div>
        </div>
        <div class="row" style={{ marginTop: 80 }}>
          <div class="col">
            <img src={hawksoft} className="img-flud  " style={{ height: 40 }} />
          </div>
          <div class="col">
            {" "}
            <img
              src={cognitoForms}
              className="img-flud  "
              style={{ height: 35 }}
            />
          </div>

          <div class="col">
            {" "}
            <img src={jotForm} className="img-flud  " style={{ height: 30 }} />
          </div>
          <div class="col">
            {" "}
            <img src={nowCert} className="img-flud  " style={{ height: 40 }} />
          </div>
          <div class="col">
            {" "}
            <img src={zoho} className="img-flud  " style={{ height: 50 }} />
          </div>
          <div class="col ">
            {" "}
            <img
              src={salesforce}
              className="img-flud  "
              style={{ height: 60 }}
            />
          </div>
        </div>
      </div>

      <div class="container mt-5 d-lg-none ">
        <div class="row mt-3">
          <div class="col-6 mt-5">
            <img src={zapier} className="img-flud  " style={{ height: 30 }} />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img
              src={agencyZoom}
              className="img-flud  "
              style={{ height: 30 }}
            />
          </div>

          <div class="col-6 mt-5">
            {" "}
            <img src={salt} className="img-flud  " style={{ height: 30 }} />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img
              src={canopyConnect}
              className="img-flud  "
              style={{ height: 30 }}
            />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img
              src={riskAdvizor}
              className="img-flud "
              style={{ height: 25 }}
            />
          </div>

          <div class="col-6 mt-5">
            <img src={hawksoft} className="img-flud " style={{ height: 30 }} />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img
              src={cognitoForms}
              className="img-flud "
              style={{ height: 25 }}
            />
          </div>

          <div class="col-6 mt-5">
            {" "}
            <img src={jotForm} className="img-flud " style={{ height: 25 }} />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img src={nowCert} className="img-flud  " style={{ height: 30 }} />
          </div>
          <div class="col-6 mt-5">
            {" "}
            <img src={zoho} className="img-flud  " style={{ height: 30 }} />
          </div>
          <div class="col-6 mt-5 ">
            {" "}
            <img
              src={salesforce}
              className="img-flud  "
              style={{ height: 40 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LastComponent = ({ screenWidth, isMobile }) => {
  console.log(screenWidth);
  return (
    <div class="container">
      <div
        class="row"
        style={{
          marginTop: isMobile ? 0 : 15,
          marginBottom: !isMobile ? "9.5%" : 70,
        }}
      >
        <div className="col-lg-8 col-sm-12">
          {/* <div style={{ width: "469px", height: "115px", textAlign: "left" }}> */}
          <div style={{}} className="col-lg-8  text-left">
            <h2
              className="d-lg-none d-xl-none"
              style={{
                fontSize: 36,
                fontWeight: "bold",
                color: "#2B2928",
              }}
            >
              Backed by
              <br /> visionaries
            </h2>
            <h2
              className="d-none d-lg-block d-xl-block"
              style={{
                fontSize: 48,
                fontWeight: "bold",
                color: "#2B2928",
              }}
            >
              Backed by visionaries
            </h2>
            <p
              style={{
                color: "#656565",
                fontSize: "16px",
                lineHeight: "25.92px",
              }}
            >
              We're backed by Asurion CEO, Ramp CTO, and Young Alfred CEO, along
              with Pear VC and Cardinal Ventures.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div class=" d-none d-lg-block d-xl-block ">
            <div class="row " style={{}}>
              <div class="col-6">
                <img
                  src={asurion}
                  className="img-flud "
                  style={{ float: "left", height: 45 }}
                />
              </div>
              <div class="col-6 ">
                {" "}
                <img
                  src={startx}
                  className="img-flud "
                  style={{ float: "left", height: 40 }}
                />
              </div>
            </div>
            <div class="row" style={{ marginTop: 50 }}>
              <div class="col-6">
                <img
                  src={pear}
                  className="img-flud "
                  style={{ float: "left", height: 60 }}
                />
              </div>
              <div class="col-6" style={{}}>
                <img
                  src={ramp}
                  className="img-flud "
                  style={{ float: "left", height: 60 }}
                />
              </div>
            </div>
          </div>

          <div class="container mt-5 d-lg-none ">
            <div class="row">
              <div class="col-6 ">
                {/* <img src={asurion} className="img-flud h-20" style={{}} /> */}
                <img
                  src={asurion}
                  className="img-flud "
                  style={{ height: 45 }}
                />
              </div>
              <div class="col-6 ">
                <img
                  src={startx}
                  className="img-flud "
                  style={{ height: 35 }}
                />
              </div>

              <div style={{ marginTop: 30 }}></div>

              <div class="col-6 mt-3">
                <img
                  src={pear}
                  className="img-flud "
                  style={{ height: 60, marginTop: 10 }}
                />
              </div>
              <div class="col-6 mt-3" style={{}}>
                <img
                  src={ramp}
                  className="img-flud "
                  style={{ height: 60, marginTop: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TriangleComponentSVG = () => {
  return (
    <div>
      <img
        src={rectangleL}
        style={{
          position: "absolute",
          zIndex: 0,
          right: 0,
          top: 120,
          width: "400px",
          height: "600px",
        }}
      />
    </div>
  );
};

const TriangleComponentSVGMobile = () => {
  return (
    <div>
      <img
        src={rectangleL}
        style={{
          position: "absolute",
          zIndex: -1,
          right: 0,
          top: 220,
        }}
      />
    </div>
  );
};

const GayaLoveComponent = ({ isMobile }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <div
        style={{
          width: "100%",
          //height: "735px",
          borderRadius: "20px",
          backgroundColor: "#FEF7F4",
          paddingTop: "100px",
          backgroundImage: `url("${semiCircle1}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "280px 280px",
        }}
      >
        <div style={{}}>
          <h1
            style={{
              marginLeft: "100px",

              textAlign: "left",
              fontSize: "48px",
              fontWeight: "bold",
            }}
          >
            Gaya Love
          </h1>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6"
              style={{
                width: "468px",
                height: "410px",
                padding: "40px",
                borderRadius: "10px",
                // border: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                border: "1px solid rgba(231, 231, 231, 0.5)",
                marginLeft: !isMobile ? "100px" : 0,
                marginTop: "50px",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ width: "388px", height: "156px", marginTop: 5 }}>
                <h2
                  style={{
                    fontSize: "16px",
                    color: "#656565",
                    lineHeight: "25.92px",
                    textAlign: "left",
                  }}
                >
                  While carriers threw raters under the bus, Gaya has been what
                  we needed. From integrating with SALT to working with our
                  carriers and AMS, they have streamlined our processes. Gaya
                  has also made us better underwriters, forcing my staff to
                  figure out which carriers are right for the client, thus
                  increasing our quote-to-bind ratios

                </h2>
              </div>

              <div
                style={{
                  width: "240px",
                  height: "75px",
                  alignItems: "flex-start",
                  display: "flex",
                  marginTop: 20,
                  textAlign: "left",
                }}
              >
                <img
                  src={love2}
                  alt=""
                  style={{ width: 75, height: 75, borderRadius: 250 }}
                />
                <div
                  style={{
                    width: "140px",
                    height: "48px",
                    marginLeft: 30,
                    marginTop: 12,
                  }}
                >
                  <h3
                    style={{
                      color: "#2B2928",
                      fontSize: "16px",
                      lineHeight: "17.6px",
                    }}
                  >
                    Dereck Kartchner
                  </h3>
                  <h3
                    style={{
                      color: "#656565",
                      fontSize: "16px",
                      lineHeight: "17.6px",
                    }}
                  >
                    GILA Insurance
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="col-md-6"
              style={{
                width: "468px",
                height: "410px",
                padding: "40px",
                paddingBottom: 0,
                borderRadius: "10px",
                // border: "1px",
                backgroundColor: "white",
                border: "1px solid rgba(231, 231, 231, 0.5)",
                marginLeft: !isMobile ? "100px" : 0,
                justifyContent: "space-between",
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div style={{ width: "388px", height: "156px", marginTop: 5 }}>
                <h2
                  style={{
                    fontSize: "16px",
                    color: "#656565",
                    lineHeight: "25.92px",
                    textAlign: "left",
                    //marginTop : -20,
                  }}
                >
                  Gaya had reduced our quote time by 80%. We have many carriers
                  that are not bridging out of EzLynx. Gaya supercopy auto and
                  home records and pastes them in a heart-beat.
                </h2>
              </div>

              <div
                style={{
                  width: "300px",
                  height: "75px",
                  alignItems: "flex-start",
                  display: "flex",
                  marginBottom: 38,
                  textAlign: "left",
                }}
              >
                <img
                  src={love1}
                  alt=""
                  style={{ width: 75, height: 75, borderRadius: 250 }}
                />
                <div
                  style={{
                    //width: "200px",
                    height: "48px",
                    marginLeft: 30,
                    marginTop: 12,
                  }}
                >
                  <h3
                    style={{
                      color: "#2B2928",
                      fontSize: "16px",
                      lineHeight: "17.6px",
                    }}
                  >
                    Ken Abel
                  </h3>
                  <h3
                    style={{
                      color: "#656565",
                      fontSize: "16px",
                      lineHeight: "17.6px",
                    }}
                  >
                    Trailstone Insurance
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            marginTop: 50,
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "100px",
            width: "54px",
            justifyContent: "space-between",
          }}
        >
          <FaCircle style={{ color: "#EF5A24" }} />
          <FaCircle style={{ color: "rgba(231, 231, 231, 1)" }} />
        </div> */}

        <div style={{ marginTop: 80 }}></div>
      </div>
    </div>
  );
};

const GayaLoveComponentMobile = ({ isMobile }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    //dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index),
  };

  useEffect(() => {
    console.log("Current slide:", currentSlide);
    // Perform actions based on the current slide index
  }, [currentSlide]);

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        paddingBottom: "10px",
        textAlign: "left",
        marginTop: "100px",
        marginBottom: "70px",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#FEF7F4",
          padding: 20,
          backgroundImage: `url("${semiCircle1}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "100px 100px",
        }}
      >
        <div>
          <h1
            style={{
              marginTop: "30px",
              marginLeft: 10,
              fontSize: "36px",
              fontWeight: "bold",
              color: "rgba(43, 41, 40, 0.9)",
            }}
          >
            Gaya Love
          </h1>
        </div>

        <div className="container">
          <Slider
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              padding: "30px",
              marginTop: 30,
              borderRadius: "10px",
              border: "1px solid rgba(231, 231, 231, 0.5)",
            }}
            {...settings}
          >
            <div>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                  height: 390,
                  //backgroundColor: "red"
                }}
              >
                <div style={{}}>
                  <h2
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "rgba(101, 101, 101, 0.8)",
                    }}
                  >
                    While carriers threw raters under the bus, Gaya has been
                    what we needed. From integrating with SALT to working with
                    our carriers and AMS, they have streamlined our processes.
                    Gaya has also made us better underwriters, forcing my staff
                    to figure out which carriers are right for the client, thus
                    increasing our quote-to-bind ratios
                  </h2>
                </div>

                <div
                  style={{
                    height: "75px",
                    alignItems: "flex-start",
                    display: "flex",
                    marginTop: 40,
                    textAlign: "left",
                  }}
                >
                  <img
                    src={love2}
                    alt=""
                    style={{ width: 75, height: 75, borderRadius: 250 }}
                  />
                  <div
                    style={{ height: "60px", marginLeft: 30, marginTop: 12 }}
                  >
                    <h3 style={{ color: "#2B2928", fontSize: "16px" }}>
                      Dereck Kartchner
                    </h3>
                    <h3 style={{ color: "#656565", fontSize: "16px" }}>
                      GILA Insurance
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                  height: 390,
                  //backgroundColor: "red"
                }}
              >
                <div style={{}}>
                  <h2
                    style={{
                      textAlign: "left",
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "rgba(101, 101, 101, 0.8)",
                    }}
                  >
                    Gaya had reduced our quote time by 80%. We have many
                    carriers that are not bridging out of EzLynx. Gaya supercopy
                    auto and home records and pastes them in a heart-beat.
                  </h2>
                </div>

                <div
                  style={{
                    height: "75px",
                    //alignItems: "flex-start",
                    display: "flex",
                    textAlign: "left",
                  }}
                >
                  <img
                    src={love1}
                    alt=""
                    style={{ width: 75, height: 75, borderRadius: 250 }}
                  />
                  <div
                    style={{ height: "60px", marginLeft: 30, marginTop: 12 }}
                  >
                    <h3 style={{ color: "#2B2928", fontSize: "16px" }}>
                      Ken Abel
                    </h3>
                    <h3 style={{ color: "#656565", fontSize: "16px" }}>
                      Trailstone Insurance
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        <div
          style={{
            marginTop: 50,
            marginBottom: 50,
            display: "flex",
            alignItems: "flex-start",
            marginLeft: "20px",
            width: "54px",
            justifyContent: "space-between",
          }}
        >
          <FaCircle
            style={{
              color: currentSlide === 0 ? "#EF5A24" : "rgba(43, 41, 40, 0.3)",
            }}
          />
          <FaCircle
            style={{
              color: currentSlide === 1 ? "#EF5A24" : "rgba(43, 41, 40, 0.3)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

function Page1() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("79%");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("79%");
      setIsMobile(false);
    }
    const newStyle = styleBox(width);
    setStyle(newStyle);
  }, [width]);

  return (
    <div
      className="App"
      style={{
        fontFamily: "Manrope, sans-serif",
      }}
    >
      <NavigationBar isMobile={isMobile} />

      {isMobile ? <TriangleComponentSVGMobile /> : <TriangleComponentSVG />}

      <div style={style.component1}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 80,
            marginTop: 50,
          }}
        >
          <h1 className=" font-weight-normal display-1 px-5">
            Super-Copy & Super-Paste <br />
            with Gaya AI
          </h1>
          <h3 style={{ marginTop: 30, color: "#EF5A24", marginBottom: 30 }}>
            Simple. Yet Powerful.
          </h3>
          <Button
            type="primary"
            style={{
              backgroundColor: "#521184",
              borderRadius: 100,
              width: "169px",
              height: "52px",
            }}
            onClick={() => {
              //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
              window.open(
                "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                "_blank"
              );
            }}
          >
            GET STARTED
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: 90,
          marginBottom: 90,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
        className="col-lg-9 col-sm-11 col-md-0"
      >
        <div
          className="embed-responsive embed-responsive-16by9"
          style={{ borderRadius: "20px", overflow: "hidden" }}
        >
          {/* Replace the video URL with your actual video URL */}
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/WQGSMFH4c-s"
            controls
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <hr
        style={{
          width: "90%",
          margin: "auto",
          marginTop: 70,
          marginBottom: 70,

          color: "gray",
        }}
      />

      <div className="container">
        <div className="row  ">
          <div className="col-lg-6 col-sm-12 text-left" style={{}}>
            <div
              style={{
                margin: "0 auto",
                alignSelf: "center",
                marginBottom: 30,
              }}
              className="col-lg-9 col-md-12 col-sm-12"
            >
              <div className="d-none  d-xl-block" style={{ marginTop: 100 }} />
              <h2
                className="d-lg-none d-xl-none"
                style={{
                  fontSize: 36,
                  fontWeight: "bold",
                  color: "#2B2928",
                }}
              >
                Quote 10x Faster
              </h2>
              <h2
                className="d-none d-lg-block d-xl-block"
                style={{
                  fontSize: 48,
                  fontWeight: "bold",
                  color: "#2B2928",
                }}
              >
                Quote 10x Faster
              </h2>
              <h2
                style={{
                  marginTop: 30,
                  marginBottom: 30,
                  fontSize: 24,
                  color: "#2B2928",
                  lineHeight: "38.89px",
                }}
                className=""
              >
                {" "}
                Gaya enables insurance agents to{" "}
                <span
                  style={{ color: "#EF5A24", fontSize: 24, fontWeight: 600 }}
                >
                  quote faster than ever before
                </span>
              </h2>
              <h4
                style={{
                  fontSize: 18,
                  color: "#656565",
                  lineHeight: "29.16px",
                }}
                className=""
              >
                {" "}
                Instead of the tedious task of copying and pasting information
                from one carrier to another, with Gaya you can click on Super
                Copy to capturing all of the necessary details in one go and
                then click on Super Paste to quickly autofill carrier portals.{" "}
              </h4>
            </div>
          </div>
          <div className="col-lg-6 items- col-sm-12 mb-2" style={{alignItems : "center", display : "flex", flexDirection : "column"}}>
            <img
              src={home_im_1}
              className="img-fluid  d-sm-block d-md-block d-lg-none"
              alt="Image"
              //width={"94%"}
              style={{maxHeight : "397px",}}
            />
            <img
              src={home_im_1}
              className="img-fluid float-right d-none  d-lg-block  d-xl-block"
              alt="Image"
              style={{maxHeight : "525px",}}
              //width={"100%"}
            />
          </div>
        </div>
        {/* Row 2: Image and Text */}
        <div className="row mt-1  p-lg-5">
        <div className="col-md-6 col-sm-12">
            <img
              src={home_im_2}
              className="img-fluid   d-none d-lg-block d-xl-block"
              alt="Image"
              //width={"100%"}
              style={{maxHeight : "550px",}}
            />
          </div>
          <div className="col-lg-6 col-sm-12 text-left mb-5">
            <div
              style={{
                margin: "0 auto",
                alignSelf: "center",
              }}
              className="col-lg-10 col-md-12 col-sm-12"
            >
              <div className="d-none d-xl-block" style={{ marginTop: 100 }} />

              <h2
                style={{
                  fontSize: 36,
                  fontWeight: "bold",
                  color: "#2B2928",
                  // lineHeight: "44.5px",
                }}
                className=""
              >
                Focus on building relationships with your customers
              </h2>
              <h4
                style={{
                  fontSize: "18px",
                  color: "#656565",
                  marginTop: 20,
                  marginBottom: 20,
                  lineHeight: "29.16px",
                }}
                className=""
              >
                Whether you are quoting a Personal Line Home and Auto or
                Commercial Line policy, Gaya gives back the time you need to
                build rapport and trust with your client by taking over tedious
                data entry work.
              </h4>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#521184",
                  borderRadius: 100,
                  width: "169px",
                  height: "52px",
                }}
                onClick={() => {
                  //window.location = "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                  window.open(
                    "https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01",
                    "_blank"
                  );
                }}
              >
                GET STARTED
              </Button>
            </div>
          </div>
          <div>
            <img
              src={home_im_2}
              className="img-fluid  d-sm-block d-md-block d-lg-none"
              alt="Image"
              width={"94%"}
            />
          </div>
        </div>

        {/*Divider */}
        <div
          style={{
            // width: screenWidth,
            alignItems: "center",
            margin: "0 auto",
            marginTop: 50,
          }}
        >
          <Divider
            style={{
              borderWidth: 2,
            }}
          />
        </div>

        <div
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            textAlign: "left",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <h2
            className="d-lg-none d-xl-none"
            style={{
              fontSize: 36,
              fontWeight: "bold",
              color: "#2B2928",
            }}
          >
            How it works
          </h2>
          <h2
            className="d-none d-lg-block d-xl-block"
            style={{
              fontSize: 48,
              fontWeight: "bold",
              color: "#2B2928",
            }}
          >
            How it works
          </h2>
        </div>
        <div
          style={{
            paddingTop: 40,
            backgroundImage: `url("${isMobile ? circle2 : circle}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              justifyContent: "space-between",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            className="row "
          >
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 ">
              <div
                style={{
                  backgroundColor: "rgba(246, 237, 252, 1)",
                  height: isMobile ? null : "675px",
                  borderRadius: 20,
                  marginBottom: 40,
                }}
                className="px-3 py-3"
              >
                <img src={superCopy} className="w-100" />
                <div
                  style={{
                    // height: "224px",
                    textAlign: "left",
                    alignItems: "center",
                  }}
                  className="px-4"
                >
                  <h2
                    style={{
                      color: "#9E3EE7",
                      fontWeight: "bold",
                      marginTop: 40,
                      marginBottom: 14,
                    }}
                  >
                    Super Copy
                  </h2>
                  <h4
                    style={{
                      color: "#656565",
                      fontSize: "18px",
                      lineHeight: "30px",
                    }}
                  >
                    Gaya's AI-powered technology scans and extracts data from
                    carrier portals, or Agency Management Systems (AMS). It can
                    also extract information from physical documents including
                    carriers' declaration (dec) pages and Acord Forms. You can
                    even take screenshots and Gaya will intelligently recognize
                    and capture relevant information.
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="d-block  d-md-none  d-lg-none"
              style={{ height: "30vh" }}
            />
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
              <div
                style={{
                  backgroundColor: "rgba(253, 242, 237, 1)",
                  height: isMobile ? null : "675px",
                  borderRadius: 20,
                  marginBottom: 40,
                }}
                className="px-3 py-3"
              >
                <img
                  src={superPaste}
                  className="w-100"

                  // style={style.img}
                />
                <div
                  style={{
                    // width: style.component4Width,
                    // height: "224px",
                    // marginTop: 20,
                    textAlign: "left",
                    // marginLeft: 10,
                    alignItems: "center",
                    // margin: "0 auto",
                  }}
                  className="px-4"
                >
                  <h2
                    style={{
                      color: "#EF5A24",
                      fontWeight: "bold",
                      marginTop: 40,
                      marginBottom: 14,
                    }}
                  >
                    Super Paste
                  </h2>
                  <h4
                    style={{
                      color: "#656565",
                      fontSize: "18px",
                      lineHeight: "30px",
                    }}
                  >
                    Once the data is captured and added to your Super Clipboard,
                    Gaya seamlessly pastes this information automatically
                    filling carrier portals and other insurance web systems.
                    <b>
                      {" "}
                      Whether the form contains an input field, a checkbox, a
                      radio button or a drop-down
                    </b>
                    , Gaya’s AI will deal with it.{" "}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Component 5*/}
      {/* {isMobile ? <LogoComponentMobile /> : <LogoComponent />} */}
      <LogoComponent />
      <div style={style.component6}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <h1 style={style.component6_c1}>
            We work seamlessly with most other insurance systems (AMS, CRM,
            forms) and can adapt to any agency's custom workflows.
          </h1>
          <h3 style={style.component6_c2}>
            Gaya can also ingest and send structured data through our API and
            webhook ecosystem.
          </h3>
          <Button
            onClick={() => {
              window.location.href = "/developers";
            }}
            style={{
              width: "251px",
              height: "50px",
              borderRadius: 100,
              alignItems: "center",
              justifyContent: "center",
              borderColor: "#EF5A24",
              marginTop: 20,
              color: "#EF5A24",
              fontWeight: 600,
            }}
          >
            Check our Developers page
          </Button>
        </div>
      </div>
      {/*Divider */}
      <hr
        style={{
          width: "90%",
          // alignItems: "center",
          margin: "auto",
          marginTop: 100,
          marginBottom: 50,

          color: "gray",
        }}
      />
      {/* <div
        style={{
          // width: screenWidth,
          alignItems: "center",
          margin: "0 auto",
          marginTop: 50,
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div> */}
      {/*Component 7 */}
      <div
        style={
          !isMobile
            ? {
                // top: 200,
                //marginLeft: -100,
                backgroundImage: `url("${triangle1}")`,
                backgroundRepeat: "no-repeat",
                width: "100%",
                // height: 500,
                // backgroundPosition: "left",
                backgroundSize: "contain",
              }
            : null
        }
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-4 col-sm-12 ">
              <div
                className=""
                style={{
                  // width: "366px",
                  // height: "132px",
                  textAlign: "left",
                  //paddingRight: 70,
                  fontSize: "48px",
                  color: "#2B2928",
                  paddingLeft: isMobile ? 15 : 0,
                  paddingRight: isMobile ? 15 : 0,
                  marginBottom: 35,
                }}
              >
                <h2
                  className="d-lg-none d-xl-none"
                  style={{
                    fontSize: 36,
                    fontWeight: "bold",
                    color: "#2B2928",
                  }}
                >
                  Coming Soon on <br />
                  Gaya AI
                </h2>
                <h2
                  className="d-none d-lg-block d-xl-block"
                  style={{
                    fontSize: 48,
                    fontWeight: "bold",
                    color: "#2B2928",
                  }}
                >
                  Coming Soon <br />
                  on Gaya AI
                </h2>
              </div>

              {/* {!isMobile && (
              <div>
                <div style={{ marginTop: 100 }}></div>

                <div
                  style={{
                    top: 200,
                    //marginLeft: -100,
                    backgroundImage: `url("${triangle1}")`,
                    backgroundRepeat: "no-repeat",
                    width: 200,
                    height: 500,
                    backgroundPosition: "left",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            )} */}
            </div>

            <div
              className="col-lg-8 col-sm-12"
              style={{
                paddingLeft: isMobile ? 25 : 0,
                paddingRight: isMobile ? 25 : 0,
              }}
            >
              <div>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    justifyContent: "space-between",
                    //backgroundColor : "blue"
                  }}
                  className="row "
                >
                  <div className=" col-lg-6 col-sm-12  mb-5 ">
                    <div
                      style={{
                        width: "100%",
                        minHeight: !isMobile ? 460 : null,
                        borderRadius: "20px",
                        padding: 40,
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 0px 26px 0px #12101314",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={brain}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>

                      <div
                        style={{
                          // width: "287px",
                          // height: "240px",
                          textAlign: "left",
                          gap: "6px",
                        }}
                      >
                        <h2
                          className="text-uppercase"
                          style={{
                            fontSize: "22px",
                            fontWeight: 600,
                            paddingRight: 40,
                          }}
                        >
                          Insurance fine-tuned LLMs
                        </h2>
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#656565",
                            //textAlign: "justify",
                            lineHeight: "29.16px",
                          }}
                        >
                          We are building and fine-tuning insurance-specific
                          multimodal Large Language and embedding models with
                          immense potential for elevating insurance agents’ role
                          as trusted advisors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12  mb-5 " style={{}}>
                    <div
                      style={{
                        width: "100%",
                        minHeight: !isMobile ? 460 : null,
                        borderRadius: "20px",
                        padding: 40,
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 0px 26px 0px #12101314",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={bot_mail}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <h2
                          className="text-uppercase"
                          style={{ fontSize: "22px", fontWeight: 600 }}
                        >
                          Smart email integrations
                        </h2>
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#656565",
                            textAlign: "left",
                            lineHeight: "29.16px",
                          }}
                        >
                          Gaya will soon enhance email interactions by drafting
                          responses to service requests in your style,
                          intelligently sourcing the correct records from
                          insurtech systems for quicker, personalized replies
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className=" col-lg-12 col-sm-12  ">
                    <div
                      style={{
                        width: "100%",
                        borderRadius: "20px",
                        padding: 40,
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 0px 26px 0px #12101314",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          src={bot}
                          style={{ width: "80px", height: "80px" }}
                        />
                      </div>
                      <div
                        style={{
                          //width: "686px",
                          //height: "152px",
                          textAlign: "left",
                          gap: "6px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "22px",
                            paddingRight: 40,
                            fontWeight: 600,
                          }}
                        >
                          World’s first Insurance AI Customer Service Rep (CSR)
                        </h2>
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#656565",
                            textAlign: "justify",
                            lineHeight: "29.16px",
                          }}
                        >
                          We will be releasing the world’s first autonomous AI
                          insurance agents powered by Computer Vision and our
                          Machine Learning models to effectively navigate around
                          carrier portals and insurance systems to perform
                          service tasks. You will have an army of digital
                          workforce at your disposal.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isMobile ? (
        <ComingSoonComponent isMobile={isMobile} />
      ) : (
        <ComingSoonComponentMobile />
      )} */}
      {/*Component 8 */}
      {/*isMobile ? <GayaLoveComponentMobile /> : <GayaLoveComponent />*/}
      {/*Component 9 */}
      {/* {isMobile ? (
        <LastComponentMobile screenWidth={screenWidth} />
      ) : (
        <LastComponent screenWidth={screenWidth} />
      )} */}
      <div style={{ justifyContent: "center", padding: !isMobile ? 100 : 0 }}>
        {isMobile ? (
          <GayaLoveComponentMobile isMobile={isMobile} />
        ) : (
          <GayaLoveComponent isMobile={isMobile} />
        )}
      </div>

      <LastComponent screenWidth={screenWidth} isMobile={isMobile} />

      {/* <div style={{ marginTop: "200px" }}></div> */}
      <Footer />
    </div>
  );
}

const styleBox = (width) => {
  let device;
  if (width <= 768) {
    device = "MOBILE";
  } else {
    device = "OTHERS";
  }

  const mobileStyle = {
    component1: { marginTop: 0 },
    newFont: { fontSize: 50 },
    component2: { alignItems: "center" },
    component2_c1: {
      textAlign: "left",
      fontFamily: "Manrope, sans-serif",
      paddingRight: 40,
      paddingTop: 40,
      paddingLeft: 20,
    },
    component2_c2: {
      width: "88%",
      //height: "581px",
      marginLeft: 20,
      marginTop: 50,
    },
    component3: { alignItems: "center" /* flexDirection: "column-reverse"  */ },
    component3_c1: {
      textAlign: "left",
      fontFamily: "Manrope, sans-serif",
      paddingRight: 40,
      paddingTop: 40,
      paddingLeft: 20,
    },
    component3_c2: {
      width: "88%",
      //height: "581px",
      marginLeft: 20,
      marginTop: 50,
    },
    component4: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      marginLeft: 3,
      maxWidth: "100%", //"420px"
    },
    component4_c1: {
      alignItems: "center",
      width: "95%",
      margin: "0 auto",

      // height: "640px",
    },
    component4_c2: {
      alignItems: "center",
      width: "95%",
      margin: "0 auto",
      // height: "640px",
      // marginTop: 250,
    },
    img: { width: "292px", height: "169px", marginTop: 40, marginBottom: 40 },
    component4Width: "292px",
    component6: {
      alignItems: "center",
      justifyContent: "center",
      margin: "0 auto",
      marginTop: 60,
      width: "88%",
    },
    component6_c1: {
      fontSize: "24px",
      color: "#2B2928",
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
    component6_c2: { fontSize: "18px", marginTop: 20, color: "#656565" },
  };

  const othersStyle = {
    component1: { marginTop: 0 },
    newFont: { fontSize: 65 },
    component2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    component2_c1: {
      textAlign: "left",
      fontFamily: "Manrope, sans-serif",
      /*  paddingLeft: 80, paddingRight: 80,  */ width: "650px",
      paddingRight: 200,
    },
    component2_c2: { width: "568px", height: "611px" },
    component3: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 100,
      flexDirection: "row-reverse",
    },
    component3_c1: {
      textAlign: "left",
      fontFamily: "Manrope, sans-serif",
      /*  paddingLeft: 80, paddingRight: 80,  */ width: "650px",
      paddingLeft: 200,
    },
    component4: {
      display: "flex",
      alignItems: "center",
      width: "79%",
      margin: "0 auto",
      justifyContent: "space-between",
    },
    component4_c1: { width: "468px" },
    component4_c2: { width: "468px" },
    img: { width: "388px", height: "224px", marginTop: 40, marginBottom: 40 },
    component4Width: "388px",
    component6: {
      /*  marginLeft: 200, marginRight: 200, marginTop: 150 */ alignItems:
        "center",
      justifyContent: "center",
      margin: "0 auto",
      width: "55%",
    },
    component6_c1: {
      fontSize: "30px",
      color: "#2B2928",
      paddingTop: 40,
      paddingLeft: 40,
      paddingRight: 40,
    },
    component6_c2: {
      fontSize: "18px",
      marginTop: 20,
      color: "#656565",
      marginBottom: 30,
      paddingLeft: 130,
      paddingRight: 130,
    },
  };

  return device === "MOBILE" ? mobileStyle : othersStyle;
};

export default Page1;
