/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import NavigationBar from "../navigation/navbar";
import useWindowDimensions from "../tools/dimensions";
import { Button, Flex } from "antd";
import { Divider } from "antd";

import image1 from "../assets/images/image1.jpg";
import Footer from "../navigation/footer";
import maskot from "../assets/images/logo-cut.png";

import receivedata from "../assets/images/receivedata.png";
import senddata from "../assets/images/senddata.png";

import semiCircle1 from "../assets/images/semicircle.svg";
import wecode from "../assets/images/wecode.svg";

// import wecode from "../assets/images/wecode.svg"

const Component1 = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "1004px",
        height: "158px",
        marginTop: "50px",
        alignItems: "center",
      }}
    >
      {/*< h1 style={{}} className="display-1">
        Unleashing the Power of Gaya's API and Webhooks
      </h1> */}

      <h1 className=" font-weight-normal display-1 ">
        Unleashing the Power of Gaya's API and Webhooks
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "337px",
          height: "52px",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <Button
          onClick={() => {
            window.open(" https://staging-api.gaya.ai/");
          }}
          type="primary"
          style={{
            backgroundColor: "#521184",
            borderRadius: 100,
            width: "160px",
            height: "52px",
          }}
        >
          Client API
        </Button>

        <Button
          onClick={() => {
            window.open("https://staging-api.gaya.ai/webhooks");
          }}
          type="primary"
          style={{
            backgroundColor: "#EF5A24",
            borderRadius: 100,
            width: "160px",
            height: "52px",
          }}
        >
          Webhooks
        </Button>
      </div>
    </div>
  );
};

const Component1Mobile = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // width: "335px",
        height: "152px",
        marginTop: "50px",
        alignItems: "center",
      }}
    >
      <h1
        style={{ lineHeight: "44.4px", marginLeft: 40, marginRight: 40 }}
        className="display-1 px-2"
      >
        Unleashing the Power of Gaya's API and Webhooks
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "337px",
          height: "52px",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <Button
          onClick={() => {
            window.open(" https://staging-api.gaya.ai/");
          }}
          type="primary"
          style={{
            backgroundColor: "#521184",
            borderRadius: 100,
            width: "160px",
            height: "52px",
          }}
        >
          Client API
        </Button>

        <Button
          onClick={() => {
            window.open(" https://staging-api.gaya.ai/");
          }}
          type="primary"
          style={{
            backgroundColor: "#EF5A24",
            borderRadius: 100,
            width: "160px",
            height: "52px",
          }}
        >
          Webhooks
        </Button>
      </div>
    </div>
  );
};

const Component2 = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 50,
      }}
    >
      <div style={{ textAlign: "left", width: "468px", height: "609px" }}>
        <p
          style={{ color: "#521184", fontSize: "20px", lineHeight: "24.72px" }}
        >
          Client API
        </p>
        <div style={{ width: "468px", height: "104px" }}>
          <p style={{ fontSize: "42px", lineHeight: "51.91px" }}>
            Seamless Integration and Customization
          </p>
        </div>

        <div style={{ width: "468px", height: "156px", marginTop: 20 }}>
          <span
            style={{
              fontSize: "24px",
              lineHeight: "38.89px",
              color: "#2B2928",
            }}
          >
            Gaya’s Client API offers the flexibility to seamlessly integrate{" "}
            <span
              style={{ fontSize: "24px", color: "#9746D5", fontWeight: "bold" }}
            >
              Gaya’s advanced data extraction capabilities
            </span>{" "}
            into their existing systems.
          </span>
        </div>

        <div style={{ width: "468px", height: "116px", marginTop: 15 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            Our API enables the receipt of data from various sources including
            custom intake forms and Agency Management Systems (AMS), making it
            an essential tool for customizing and enhancing your applications.
          </span>
        </div>

        <div style={{ width: "468px", height: "87px", marginTop: 15 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#2B2928",
            }}
          >
            Dive into the details and explore the endless possibilities with our
            Client API at Gaya Client API Documentation:
          </span>
        </div>

        <div style={{ marginTop: -30 }}>
          <a
            target="_blank"
            href="https://staging-api.gaya.ai"
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#9746D5",

              textDecoration: "none",
            }}
            rel="noreferrer"
          >
            Visit staging-api.gaya.ai
            <svg
              style={{ marginLeft: 5 }}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="24" rx="12" fill="#9746D5" />
              <path
                d="M13.62 8.45331L17.6667 12.5L13.62 16.5466"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.33337 12.5H17.5534"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
      <div style={{}}>
        <img
          src={senddata}
          style={{
            width: "80%",
            float: "right",
          }}
        />
      </div>
      {/**/}
    </div>
  );
};

const Component2Mobile = () => {
  return (
    <div style={{ width: "100%", alignItems: "flex-start", marginTop: 50 }}>
      <div style={{ textAlign: "left" }}>
        <p
          style={{ color: "#521184", fontSize: "20px", lineHeight: "24.72px" }}
        >
          Client API
        </p>
        <div style={{ height: "111px" }}>
          <span style={{ fontSize: "30px", lineHeight: "37.08px" }}>
            Seamless Integration and Customization
          </span>
        </div>

        <div style={{ height: "195px", marginTop: -20 }}>
          <span
            style={{
              fontSize: "24px",
              lineHeight: "38.89px",
              color: "#2B2928",
            }}
          >
            Gaya’s Client API offers the flexibility to seamlessly integrate{" "}
            <span
              style={{ fontSize: "24px", color: "#9746D5", fontWeight: "bold" }}
            >
              Gaya’s advanced data extraction capabilities
            </span>{" "}
            into their existing systems.
          </span>
        </div>

        <div style={{ height: "283px", marginTop: 15 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            Our API enables the receipt of data from various sources including
            custom intake forms and Agency Management Systems (AMS), making it
            an essential tool for customizing and enhancing your applications.
          </span>
        </div>

        <div style={{ height: "87px", marginTop: -90 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#2B2928",
            }}
          >
            Dive into the details and explore the endless possibilities with our
            Client API at Gaya Client API Documentation:
          </span>
        </div>
        <div>
          <a
            target="_blank"
            href="https://staging-api.gaya.ai"
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#9746D5",

              textDecoration: "none",
            }}
            rel="noreferrer"
          >
            Visit staging-api.gaya.ai
            <svg
              style={{ marginLeft: 5 }}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="24" rx="12" fill="#9746D5" />
              <path
                d="M13.62 8.45331L17.6667 12.5L13.62 16.5466"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.33337 12.5H17.5534"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>

      <div
        style={{
          // width: "335px",
          //   height: "556px",
          //   backgroundColor: "#F8F3FB",
          //   marginTop: 40,
          marginTop: 20,
        }}
      >
        <img
          src={senddata}
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  );
};

const Component3 = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 50,
      }}
    >
      <div style={{}}>
        <img
          src={receivedata}
          style={{
            width: "80%",
            float: "left",
          }}
        />
      </div>

      <div style={{ textAlign: "left", width: "468px", height: "609px" }}>
        <p
          style={{ color: "#EF5A24", fontSize: "20px", lineHeight: "24.72px" }}
        >
          Webhook Ecosystem
        </p>
        <div style={{ width: "468px", height: "104px", marginTop: 30 }}>
          <p style={{ fontSize: "42px", lineHeight: "51.91px" }}>
            Dynamic Data Interaction
          </p>
        </div>

        <div style={{ width: "468px", height: "156px", marginTop: 20 }}>
          <span
            style={{
              fontSize: "24px",
              lineHeight: "38.89px",
              color: "#2B2928",
            }}
          >
            Our Webhook system is designed for dynamic and efficient
            <span
              style={{ fontSize: "24px", color: "#EF5A24", fontWeight: "bold" }}
            >
              {" "}
              interaction with extracted data.
            </span>
          </span>
        </div>

        <div style={{ width: "468px", height: "116px", marginTop: -20 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            It allows you to send processed information back to your internal or
            third-party systems, creating a responsive and interconnected data
            environment. This feature is crucial for agencies looking to
            automate and synchronize their data flow across multiple platforms.
          </span>
        </div>

        <div style={{ width: "468px", height: "87px", marginTop: 50 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#2B2928",
            }}
          >
            Learn more about how to leverage this functionality in your projects
            at Gaya Webhook Integration:
          </span>
        </div>

        <div style={{ marginTop: -30 }}>
          <a
            target="_blank"
            href="https://staging-api.gaya.ai/webhooks"
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#EF5A24",

              textDecoration: "none",
            }}
            rel="noreferrer"
          >
            Visit staging-api.gaya.ai/webhooks
            <svg
              style={{ marginLeft: 5 }}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="24" rx="12" fill="#EF5A24" />
              <path
                d="M13.6201 8.45337L17.6668 12.5L13.6201 16.5467"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.33325 12.5H17.5533"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

const Component3Mobile = () => {
  return (
    <div style={{ width: "100%", alignItems: "flex-start", marginTop: 50 }}>
      <div style={{ textAlign: "left" }}>
        <p
          style={{ color: "#EF5A24", fontSize: "20px", lineHeight: "24.72px" }}
        >
          Webhook Ecosystem
        </p>
        <div style={{ height: "111px" }}>
          <span style={{ fontSize: "30px", lineHeight: "37.08px" }}>
            Dynamic Data Interaction
          </span>
        </div>

        <div style={{ height: "195px", marginTop: -50 }}>
          <span
            style={{
              fontSize: "24px",
              lineHeight: "38.89px",
              color: "#2B2928",
            }}
          >
            Our Webhook system is designed for dynamic and efficient
            <span
              style={{ fontSize: "24px", color: "#EF5A24", fontWeight: "bold" }}
            >
              {" "}
              interaction with extracted data.
            </span>
          </span>
        </div>

        <div style={{ height: "283px", marginTop: -20 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#656565",
            }}
          >
            It allows you to send processed information back to your internal or
            third-party systems, creating a responsive and interconnected data
            environment. This feature is crucial for agencies looking to
            automate and synchronize their data flow across multiple platforms.
          </span>
        </div>

        <div style={{ height: "87px", marginTop: -60 }}>
          <span
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#2B2928",
            }}
          >
            Learn more about how to leverage this functionality in your projects
            at Gaya Webhook Integration:
          </span>
        </div>
        <div style={{}}>
          <a
            target="_blank"
            href="https://staging-api.gaya.ai/webhooks"
            style={{
              fontSize: "18px",
              lineHeight: "29.16px",
              color: "#EF5A24",

              textDecoration: "none",
            }}
            rel="noreferrer"
          >
            Visit staging-api.gaya.ai/webhooks
            <svg
              style={{ marginLeft: 5 }}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="24" height="24" rx="12" fill="#EF5A24" />
              <path
                d="M13.6201 8.45337L17.6668 12.5L13.6201 16.5467"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.33325 12.5H17.5533"
                stroke="white"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </div>

      <div
        style={{
          //   width: "335px",
          //   height: "556px",
          //   backgroundColor: "#F8F3FB",
          marginTop: 20,
        }}
      >
        <img
          src={receivedata}
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  );
};

const Component4 = () => {
  return (
    <div
      style={{
        width: "1168px",
        height: "757px",
        borderRadius: "20px",
        backgroundColor: "#FEF7F4",
        marginTop: 30,
      }}
    >
      {/* <div
        style={{
          border: "30px solid #E7E7E7",

          height: 400,
          width: 400,
          borderRadius: "50%",
          position: "absolute",

          right: "10%",
        }}
      ></div> */}
      <div>
        <div
          style={{
            paddingTop: 0,
            backgroundImage: `url("${semiCircle1}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top right",
            //backgroundSize: "cover",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                width: "418px",
                height: "304px",
                textAlign: "left",
                marginTop: 110,
              }}
            >
              <h1 style={{ fontSize: "48px", color: "#2B2928" }}>
                Beyond Insurance!
              </h1>
              <div style={{ height: "195px", marginTop: 25 }}>
                <span style={{ fontSize: "24px" }}>
                  If you are a developer streamlining workflows outside of the
                  insurance industry and think Gaya is well suited to help you,{" "}
                  <a
                    target="_blank"
                    href="https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                    style={{ color: "#EF5A24" }}
                    rel="noreferrer"
                  >
                    <u>book some time with us here.</u>
                  </a>
                </span>
              </div>

              <div style={{ height: "87px", marginTop: 10 }}>
                <span style={{ fontSize: "18px", color: "#656565" }}>
                  While we are laser focused on our insurance customers, we
                  welcome opportunities to learn more about challenges faced
                  elsewhere!
                </span>
              </div>

              {/* <img
          src={maskot}
          style={{
            //   width: "48px",
            height: "120px",
            borderRadius: "20px",
            marginTop: 195,
            position: "relative",
            bottom: 40,
            left: -70,
            zIndex: 999,
          }}
        /> */}
            </div>

            <img
              src={image1}
              style={{
                width: "468px",
                zIndex: 10,
                height: "434px",
                borderRadius: "20px",
                marginTop: 195,
              }}
            />
          </div>
          <div>
            <img
              src={maskot}
              style={{
                //   width: "48px",
                marginLeft: -0,
                marginTop: 10,
                height: "120px",
                borderRadius: "20px",
                float: "left",
              }}
            />
          </div>
        </div>
      </div>

      {/* <svg
        style={{
          position: "absolute",
          right: 170,
          marginTop: 0,
          zIndex: 0,
        }}
        width="15%"
        // height="276"
        viewBox="0 0 264 276"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 0V12C15 149.519 126.481 261 264 261V261"
          stroke="#EF5A24"
          stroke-width="30"
        />
      </svg> */}
    </div>
  );
};

const Component4Mobile = () => {
  return (
    <div
      style={{
        width: "90%",
        // height: "789px",
        borderRadius: "20px",
        backgroundColor: "#FEF7F4",
        marginTop: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          paddingTop: 0,
          backgroundImage: `url("${semiCircle1}")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "100px 100px",
        }}
      >
        <div
          style={{
            padding: 40,
            // height: "544px",
            textAlign: "left",
            marginTop: 50,
          }}
        >
          <div style={{}}>
            <h1 style={{ fontSize: "30px", color: "#2B2928", fontWeight: 600 }}>
              Beyond <br /> Insurance!
            </h1>
          </div>

          <div style={{ marginTop: 25 }}>
            <span style={{ fontSize: "22px" }}>
              If you are a developer streamlining workflows outside of the
              insurance industry and think Gaya is well suited to help you,{" "}
              <a
                target="_blank"
                href="https://calendly.com/d/cm3-53m-qm4/gaya-team?month=2024-01"
                style={{ color: "#EF5A24" }}
                rel="noreferrer"
              >
                <u>book some time with us here.</u>
              </a>
            </span>
          </div>

          <div style={{ marginTop: 10 }}>
            <span style={{ fontSize: "18px", color: "#656565" }}>
              While we are at the moment laser focused on our insurance
              customers, we welcome opportunities to learn more about challenges
              faced elsewhere!
            </span>
          </div>
          <img
            src={image1}
            style={{
              height: 300,
              objectFit: "cover",
              width: "100%",
              borderRadius: "20px",
              marginTop: 20,
            }}
          />
          {/* <div>
            <img
              src={maskot}
              style={{
                //   width: "48px",
                marginLeft: -40,
                marginBottom: -40,
                height: "80px",
                borderRadius: "20px",
                float: "left",
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const TriangleComponentSVG = () => {
  return (
    <div>
      <svg
        style={{ position: "absolute", top: -20, right: 0, zIndex: -1 }}
        width="116"
        height="712"
        viewBox="0 0 116 712"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="404.264"
          y="-116"
          width="600"
          height="600"
          rx="50"
          transform="rotate(45 404.264 -116)"
          fill="#FEF7F4"
        />
      </svg>

      <svg
        style={{ position: "absolute", top: -20, left: -20, zIndex: -1 }}
        width="115"
        height="712"
        viewBox="0 0 115 712"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-288.736"
          y="-116"
          width="600"
          height="600"
          rx="50"
          transform="rotate(45 -288.736 -116)"
          fill="#F8F3FB"
        />
      </svg>
    </div>
  );
};

function Page2() {
  const { height, width } = useWindowDimensions();

  const [screenWidth, setScreenWidth] = useState("1168px");
  const [isMobile, setIsMobile] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    //console.log("New width : "+ width)
    if (width <= 768 && !isMobile) {
      setScreenWidth("88%");
      setIsMobile(true);
    }

    if (width > 768 && isMobile) {
      setScreenWidth("1168px");
      setIsMobile(false);
    }
    // const newStyle = styleBox(width)
    // setStyle(newStyle)
  }, [width]);

  return (
    <div>
      <NavigationBar isMobile={isMobile} selected={"Developers"} />
      <TriangleComponentSVG />
      <div
        style={{
          // width: screenWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component1Mobile /> : <Component1 />}
      </div>
      <div
        style={{
          width: screenWidth,
          alignItems: "center",
          margin: "0 auto",
          marginTop: "150px",
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div>
      <div
        style={{
          width: screenWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component2Mobile /> : <Component2 />}
      </div>
      <div
        style={{
          width: screenWidth,
          alignItems: "center",
          margin: "0 auto",
          marginTop: "50px",
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div>
      <div
        style={{
          width: screenWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component3Mobile /> : <Component3 />}
      </div>
      <div
        style={{
          width: screenWidth,
          alignItems: "center",
          margin: "0 auto",
          marginTop: "50px",
        }}
      >
        <Divider
          style={{
            borderWidth: 2,
          }}
        />
      </div>
      <div
        style={{
          // width: screenWidth,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        {isMobile ? <Component4Mobile /> : <Component4 />}
      </div>{" "}
      {/**/}
      <div style={{ marginTop: 50 }}></div>
      <Footer />
    </div>
  );
}

export default Page2;
